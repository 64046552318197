import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "utils/classNames";
import PageSection from "components/common/PageSection";
import "./error.scss";

function ErrorPage({ error }) {
  // const footer = document.querySelector('footer');
  // const menu = document.querySelector('.menu-container');
  // const footerBCR = footer.getBoundingClientRect();
  // const menuBCR = menu.getBoundingClientRect();
  // const pageHeight = window.innerHeight - footerBCR.height - menuBCR.height;

  return (
    <div className={classNames.page("error")}>
      <PageSection modifiers="error">
        <section className="error-message">
          <h1>404</h1>
          <h3>page not found</h3>
          <p className="body-copy">
            Unfortunately, this page is either unavailable or can no longer be
            found. Please click <Link to={"/"}>here</Link> to go back to our
            homepage.
          </p>
          {/* <code>
            {error}
          </code> */}
        </section>
      </PageSection>
    </div>
  );
}

ErrorPage.propTypes = {
  error: PropTypes.object,
};

export default ErrorPage;
