import React from "react";
import PropTypes from "prop-types";
import "./content-block-leadin.scss";

function ContentBlockLeadIn( { leadIn } ) {
  return (<div className='content-block content-block-leadin' dangerouslySetInnerHTML={{ __html: leadIn }}></div>);
}

ContentBlockLeadIn.propTypes = {
  leadIn: PropTypes.string.isRequired
};

export default ContentBlockLeadIn;  