// /* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import LogoHorizontalFullColorWhite from "components/svg/LogoHorizontalFullColorWhite";
import SocialIconInstagram from "components/svg/SocialIconInstagram";
import SocialIconFacebook from "components/svg/SocialIconFacebook";
import SocialIconTwitter from "components/svg/SocialIconTwitter";
import SocialIconLinkedin from "components/svg/SocialIconLinkedin";

function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="footer__inner">
        <div className="footer__col">
          <h4>QUICK LINKS</h4>
          <Link to="/contact">Contact Us</Link>
          <Link to="/about">About Us</Link>
        </div>
        <div className="footer__col">
          <h4>GET IN TOUCH</h4>
          <a href="tel:7047777844">(704) 777-7844</a>
          <a href="https://www.google.com/maps/dir//801+S+Cedar+St,+Charlotte,+NC+28208/@35.2270355,-80.8583299,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8856a1d311481a79:0xc62b848b8b1c27b2!2m2!1d-80.8561359!2d35.2270355">
            <address>
              801 S Cedar St
              <br />
              Charlotte, NC 28208
            </address>
          </a>
        </div>
        <div className="footer__col">
          <h4>FOLLOW US</h4>
          <div className="sharethis-inline-follow-buttons"></div>
          <div className="social_links">
            <a
              className="social_link"
              href="https://www.facebook.com/gGROHorticulture/"
            >
              <SocialIconFacebook />
            </a>
            <a
              className="social_link"
              href="https://www.instagram.com/ggro_horticulture/"
            >
              <SocialIconInstagram />
            </a>
            <a className="social_link" href="https://twitter.com/ggrohort">
              <SocialIconTwitter />
            </a>
            <a
              className="social_link"
              href="https://www.linkedin.com/company/ggrohorticulture/"
            >
              <SocialIconLinkedin />
            </a>
          </div>
        </div>
        <div className="footer__col">
          <div className="footer__logo">
            <LogoHorizontalFullColorWhite />
            <span className="copyright">© 2022, gGRO Horticulture.</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
