/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import PageSection from "components/common/PageSection";
import Interweave from "interweave";
import classNames from "utils/classNames";
import "./two-column-text-image.scss";

function TwoColumnTextImage({ bgImg, heading, subheading, bodyCopy, asset }) {
  return (
    <PageSection
      modifiers={{ "body-copy": true, callout: "two-column-text-image" }}
      bgImg={bgImg}
    >
      <div className="two-column-text-image">
        <div className="grid-item">
          <div className={classNames.gridItem("inner")}>
            {heading && <Interweave tagName="h2" content={heading} />}
            {subheading && (
              <Interweave tagName="h3" className="h4" content={subheading} />
            )}
            {bodyCopy && <Interweave tagName="div" content={bodyCopy} />}
          </div>
        </div>
        <div className="grid-item">
          <div className={classNames.gridItem("inner")}>
            <figure className={classNames.gridItemImage()}>
              <img src={asset.url} alt={asset.title} />
            </figure>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

TwoColumnTextImage.defaultProps = {
  bgImg:
    "https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/Grow-Lamp-Background-2-v1.jpg",
};

TwoColumnTextImage.propTypes = {
  bgImg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  bodyCopy: PropTypes.string,
  asset: PropTypes.object.isRequired,
};

export default TwoColumnTextImage;
