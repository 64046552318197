import React from "react";

function BackToTopIcon() {
  const strokeWidthSize = 2;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
      <g id="back-to-top-icon" transform="translate(-614 -597)">
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="37.5" cy="37.5" r="37.5" transform="translate(614 597)" fill="#60ca49"/>
        <g id="Group_1" data-name="Group 1" transform="translate(0.5 -0.5)">
          <line id="Line_1" data-name="Line 1" x1="21" y2="21" transform="translate(630.5 613.5)" fill="none" stroke="#fff" strokeWidth={ strokeWidthSize } />
          <line id="Line_2" data-name="Line 2" x2="21" y2="21" transform="translate(650.5 613.5)" fill="none" stroke="#fff" strokeWidth={ strokeWidthSize } />
          <line id="Line_1-2" data-name="Line 1" x1="21" y2="21" transform="translate(630.5 630.5)" fill="none" stroke="#fff" strokeWidth={ strokeWidthSize } />
          <line id="Line_2-2" data-name="Line 2" x2="21" y2="21" transform="translate(650.5 630.5)" fill="none" stroke="#fff" strokeWidth={ strokeWidthSize } />
        </g>
      </g>
    </svg>

  );
}

export default BackToTopIcon;
