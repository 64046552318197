import React from "react";
import PropTypes from "prop-types";

function ContentBlockLeadCopy({leadCopy}) {
  return (<h2 className='blog--article-leader'>{leadCopy}</h2>);
}

ContentBlockLeadCopy.propTypes = {
  leadCopy: PropTypes.string.isRequired
};

export default ContentBlockLeadCopy;