import React from 'react';
import PropTypes from "prop-types";

function SocialIconLinkedin({color}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72.918" height="72.919" viewBox="0 0 72.918 72.919" aria-hidden="true" focusable="false"  role="presentation">
      <title>LinkedIn</title>
      <path id="social-icon-linkedin" d="M208.467,10.679a36.46,36.46,0,1,0,0,51.563,36.461,36.461,0,0,0,0-51.563M170.623,56.717h-8.212V29.144h8.212Zm-4.159-31.628a4.967,4.967,0,1,1,4.967-4.967,4.968,4.968,0,0,1-4.967,4.967m36.49,31.617h-8.108V41.8a5.391,5.391,0,0,0-5.2-5.573l-.17,0a5.822,5.822,0,0,0-5.879,5.575V56.7h-8.108V29.144h8.108V33A9.385,9.385,0,0,1,191.4,28.84h2.229a9.366,9.366,0,0,1,9.324,9.326Z" transform="translate(-146.226 -0.001)" fill="#fff"/>
    </svg>

  );
}

SocialIconLinkedin.defaultProps = {
  color: '#FFFFFF'
};

SocialIconLinkedin.propTypes = {
  color: PropTypes.string,
};

export default SocialIconLinkedin;