import { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

var _hsq = (window._hsq = window._hsq || []);

function AnalyticsListener({children}) {

  const location = useLocation();

  // constructor(props) {
  //   super(props);
  //   console.log('AnalyticsListener', props);
  //   return;
  //   this.sendPageView = this.sendPageView.bind(this);
  // }
  
  // componentDidMount() {
  //   this.sendPageView(this.props.location);
  //   this.props.history.listen(this.sendPageView);
  // }

  const sendPageView = (location) => {
    // console.log('sendPageView', location.pathname);
    _hsq.push(["setPath", location.pathname]);
    _hsq.push(["trackPageView"]);
  };

  useEffect(() => {
    sendPageView(location);
  }, []);

  return children;
}

AnalyticsListener.propTypes = {
  children: PropTypes.node,
  // location: PropTypes.object,
  // history: PropTypes.object,
};

// export default withRouter(AnalyticsListener);
export default AnalyticsListener;
