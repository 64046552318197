import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from "utils/classNames";
import fetchGraphQL from "gql/utils";
import BlogEntryQuery from "gql/queries/blogEntry.gql";
import useBlogTags from "hooks/useBlogTags";
import useShareThis from "hooks/useShareThis";
import DynamicContentBlock from "components/DynamicContentBlock";
import SocialIconInstagram from "components/svg/SocialIconInstagram";
import SocialIconFacebook from "components/svg/SocialIconFacebook";
import SocialIconTwitter from "components/svg/SocialIconTwitter";
import SocialIconLinkedin from "components/svg/SocialIconLinkedin";
import SocialIconLink from "components/svg/SocialIconLink";

import "./blog-shared.scss";
import "./blog-article.scss";

function KnowledgeCenterArticle() {

  const socialLinks = useRef(null);
  const socialLinkCopy = useRef(null);
  const socialLinkInstagram = useRef(null);

  const location = useLocation();
  const [uri, setUri] = useState(() => location.pathname.replace("/", ""));
  
  const [article, setArticle] = useState(null);

  const { TagFilterAllShowButton, TagFilterList } = useBlogTags({ uri: uri });
    
  const { shareThis } = useShareThis();

  const loadArticle = async (uri) => {
    console.log("BlogEntryQuery uri=", uri);
    const resp = await fetchGraphQL(BlogEntryQuery, {
      uri: uri,
    });
    console.log( resp );
    const entry = resp.entry;
    entry.featuredImage = entry.featuredImage[0];
    return entry;
  };

  const articleInfoPostDateHtml =  ( _entry ) =>
  {
    
    const articleInfoPostDate = _entry?.postDate?.split('T')[0]?.split('-') ?? null;
    
    const articleInfoPostDateParse = ( articleInfoPostDate ) ? `${articleInfoPostDate[1]}.${articleInfoPostDate[2]}.${articleInfoPostDate[0]}` : null;

    if ( !articleInfoPostDateParse ) return (<></>);

    return `<span class='blog--article-header-info-date'>${ articleInfoPostDateParse }</span>`;
  };

  const articleInfoAuthorHtml =  ( _entry ) =>
  {
    console.log(_entry );
    console.log(_entry?.author );
    //console.log(_entry?.author?.fullName.charAt(0) );
    const articleInfoAuthorParse = ( _entry?.author?.id !== '1' ) ? _entry?.author?.fullName : 'gGro Horticulture';
    console.log( articleInfoAuthorParse );
    return `<span class='blog--article-header-info-author'>${ articleInfoAuthorParse }</span>`;
  };

  const articleInforSeparatorHtml = () =>
  {
    return `<span class='blog--article-header-info-separator'>|</span>`;
  };

  const articleInfoHtml =  ( _entry ) =>
  {
    let articleInfoData = articleInfoAuthorHtml( _entry );
    articleInfoData = ( articleInfoData !== '' ) ? `${ articleInfoData }${ articleInforSeparatorHtml() }` : '';
    articleInfoData += articleInfoPostDateHtml( _entry );
    
    return (<span className='blog--article-header-info' dangerouslySetInnerHTML={{ __html: articleInfoData }}></span> );
  };

  const articleSubtitleHtml = ( _entry ) =>
  {
    const articleSubtitleParse = ( _entry?.articleSubtitle !== '' ) ? _entry.articleSubtitle : null;
    
    if ( !articleSubtitleParse ) return (<></>);

    return ( <span className='blog--article-header-subtitle'>{ articleSubtitleParse }</span> );
  };

  useEffect( () => 
  {
    const newUri = location.pathname.replace("/", "");
    if (uri !== newUri) {
      setUri(newUri);
    }
  }, [location]);

  useEffect( async () => 
  {
    if ( article && article.uri === uri ) 
    { 
      return;
    }

    const entry = await loadArticle(uri); console.log( entry );

    setArticle( entry );

    const shareThisScriptDelay = setTimeout( () => 
    {
      shareThis();

      clearTimeout( shareThisScriptDelay );
    
    }, 100);

  }, [uri]);

  return article ? (
    <>
      <div className={classNames.page('knowledgecenter-article blog--main grid grid--layers')}>
        <picture tabIndex="-1" className="z-1 blog--article-featured-image"><img src={article.featuredImage.url} alt={article.featuredImage.title} /></picture>
        <article className='blog--article z-2'>
          <header className='blog--article-header'>
            <h1 className='blog--article-header' tabIndex='0'>
              <span className='blog--article-header-tags'><TagFilterList tagged={article.articleTags} linked={true} /></span>
              {article.title}
              { articleSubtitleHtml( article ) }
              { articleInfoHtml( article ) }
            </h1>
          </header>

          {article.contentBlocks.map((contentBlock) => (
            <DynamicContentBlock key={`content-block-${contentBlock.id}`} {...contentBlock} />
          ))}
          
          {/* <p className='sharethis-inline-share-buttons'>Share</p> */}
          <div className='content-block'>
            <div className='blog--article-sharethis'>
              <span className='blog--article-sharethis-label' onClick={() => { socialLinks.current.classList.toggle('active'); }}>
                Share&nbsp;&nbsp;
                <svg className='blog--article-sharethis-icon' width='14.151' height='13.959' viewBox='0 0 14.151 13.959' aria-hidden='true' focusable='false' role='presentation'>
                  <path id='ui-icon-sharethis' d='M11.712,9.084a2.43,2.43,0,0,0-1.784.78L4.842,7.381a2.463,2.463,0,0,0,.031-.389A2.377,2.377,0,0,0,4.841,6.6L9.931,4.1a2.435,2.435,0,1,0-.656-1.66,2.377,2.377,0,0,0,.032.394l-5.089,2.5a2.437,2.437,0,1,0,0,3.319l5.085,2.483a2.438,2.438,0,1,0,2.405-2.049Z' fill='#8b8b8b'/>
                </svg>  
              </span>
              <div className='social_links' ref={socialLinks}>
                <div className='social_link st-custom-button facebook-share-button' data-network='facebook'><SocialIconFacebook /></div>
                <div className='social_link'>
                  <div className='sharethis-inline-follow-buttons' data-url='https://www.instagram.com/ggro_horticulture/?hl=en' ref={socialLinkInstagram}></div>
                  <SocialIconInstagram />
                </div>
                <div className='social_link st-custom-button twitter-share-button' data-network='twitter'><SocialIconTwitter /></div>
                <div className='social_link st-custom-button linkedin-share-button' data-network='linkedin'><SocialIconLinkedin /></div>
                <div className='social_link social_link_copy' ref={socialLinkCopy} onClick={ () => {
                  navigator.clipboard.writeText( window.location.href ).then( () => {
                    //console.log('Copy link to clipboard was successful!', window.location.href);
                    alert('Link copied');
                  }, ( _err ) => {
                    alert('Link could not be copied');
                    //console.error('Copy link to clipboard failed', _err);
                  });          
                }}><SocialIconLink /></div>
              </div>
            </div>
          </div>
          <div className='content-block-center'>
            <p><TagFilterAllShowButton /></p>
          </div>
          <div className='content-block-center'>
            <p><Link to={'/contact#contact'} className={'blog--button-white'}>Contact for a test grow</Link></p>
          </div>
        </article>
      </div>
    </>
  ) : (
    ''
  );
}

export default KnowledgeCenterArticle;
