import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import { v4 as uuidv4 } from "uuid";
import bem from "easy-bem";
import SvgText from "components/common/SvgText";

import "./stat.scss";

function Stat({
  value,
  label,
  subtext,
  labelSize,
  layout,
  justify,
  align,
  labelInset,
  fluid,
}) {
  const b = bem("stat");
  const labelId = uuidv4();

  const subtextHTML = subtext
    ? `<small class="stat__subtext">${subtext}</small>`
    : "";

  const statClassName = b({
    layout: layout,
    justify: justify,
    align: align,
  });

  return (
    <div className={statClassName} aria-labelledby={labelId}>
      <div className={b("value")}>
        {fluid ? (
          <SvgText text={value} />
        ) : (
          <div className="text-value">{value}</div>
        )}
      </div>
      <Interweave
        id={labelId}
        className={b("label", {
          size: labelSize,
          inset: labelInset,
        })}
        tagName="figcaption"
        content={label + subtextHTML}
      />
    </div>
  );
}

Stat.defaultProps = {
  subtext: null,
  labelSize: null,
  layout: "vertical",
  justify: "center",
  align: "center",
  labelInset: false,
  fluid: true,
};

Stat.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  subtext: PropTypes.string,
  layout: PropTypes.oneOf(["vertical", null, "horizontal"]),
  justify: PropTypes.oneOf(["start", "center", "end"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  labelInset: PropTypes.bool,
  labelSize: PropTypes.oneOf(["sm", null, "lg"]),
  fluid: PropTypes.bool,
};

export default Stat;
