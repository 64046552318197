import React from "react";
import PropTypes from "prop-types";
import Hero, { HeroCopy } from "components/common/Hero";
import classNames from "utils/classNames";
import PageSection from "components/common/PageSection";

// Shared components
import SpecChartCallout from "components/callouts/SpecChartCallout";
import StatsCallout from "components/callouts/StatsCallout";
import SpecificationsCallout from "components/callouts/SpecificationsCallout";
import ContactForm from "components/ContactForm";
import TwoColumnTextImage from "components/callouts/TwoColumnTextImage";
import EfficiencyCallout from "components/callouts/EfficiencyCallout";
function Led() {
  return (
    <div className={classNames.page("led")}>
      <Hero bgImg="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/led/gGROLamp-HeaderBackground.jpg">
        <HeroCopy
          title={`Save MORE <br /> THAN 35% on <br /> equipment <br />costs vs LED.`}
          subtitle={`Return on investment in less than 18 months.`}
        />
      </Hero>
      <SpecChartCallout
        src={`https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/led/led-chart.png`}
        title="ggro led comparison to hps lights"
      />
      <TwoColumnTextImage
        heading={`<em>Broader wavelength</em> <br /> lower cost.`}
        subheading={`<em>UV, IR, fAR-RED</em> one light to maximize your grams PER watt.`}
        bodyCopy={`<p>LED lights can grow plants, but are outperformed by gGRO&rsquo;s combination of high yield and energy savings. gGRO lights increase THC levels, crop health, growth speed and consistency without costly upgrades.</p>`}
        asset={{
          url: "https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/led/ggro-winners-over-led.png",
          title: "",
        }}
      />
      <EfficiencyCallout
        heading={`<em>Less than</em><br />18-month ROI`}
        bodyCopy={`<h4><em>Lower OPERATING costs, higher yield,</em><br />fewer unexpected expenses.</h4><p>  LED’s ROI can exceed 5 years. gGRO delivers a return on investment  in under 18 months. Factor in our higher crop yield, and ROI can  be acheived in under 12 months. We’ve done the research. The white  paper will be published this year. This is your chance to get a  headstart on your competition.
            </p>`}
      />
      <StatsCallout
        heading={`The bottom-line <em>benefits of gGRO</em> CMH lighting over LED.`}
        stats={[
          { value: ">35%", label: "LOWER FIXTURE COSTS" },
          { value: "20%", label: "MORE SPECTRUM" },
          { value: "18", label: "MONTH ROI" },
        ]}
      />
      <SpecificationsCallout />
      <PageSection modifiers={{ "body-copy": true }}>
        <a name='contact' />
        <center>
          <h4>Want to know more? Email us and we’ll get right back to you.</h4>
        </center>
        <ContactForm />
      </PageSection>
    </div>
  );
}

Led.propTypes = {
  data: PropTypes.object,
};

export default Led;
