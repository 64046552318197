import React from 'react';
import PropTypes from "prop-types";

function SocialIconInstagram({color}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72.918" height="72.919" viewBox="0 0 72.918 72.919" aria-hidden="true" focusable="false" role="presentation">
      <title>Instagram</title>
      <path id="social-icon-instagram" d="M110.972,10.678a36.46,36.46,0,1,0,0,51.563,36.461,36.461,0,0,0,0-51.563m-2.5,36.446A12.616,12.616,0,0,1,95.918,59.682H74.469A12.616,12.616,0,0,1,61.911,47.124V25.782A12.616,12.616,0,0,1,74.469,13.224v.011H95.918a12.616,12.616,0,0,1,12.558,12.558ZM93.583,36.459A8.41,8.41,0,0,1,85.248,44.9h-.112a8.39,8.39,0,0,1,0-16.779h.112a8.393,8.393,0,0,1,8.334,8.334M95.8,17.236H74.573A8.68,8.68,0,0,0,65.906,25.9V47.017a8.678,8.678,0,0,0,8.666,8.666H95.8a8.678,8.678,0,0,0,8.666-8.666V25.9A8.678,8.678,0,0,0,95.8,17.236m-10.548,31.9h-.112a12.67,12.67,0,0,1,0-25.34h.112a12.67,12.67,0,0,1,0,25.34M97.694,26.681a2.634,2.634,0,0,1-2.667-2.6v-.065a2.634,2.634,0,0,1,2.6-2.667h.065a2.667,2.667,0,1,1,0,5.334" transform="translate(-48.731)" fill="#fff"/>
    </svg>
  );
}

SocialIconInstagram.defaultProps = {
  color: '#FFFFFF'
};

SocialIconInstagram.propTypes = {
  color: PropTypes.string,
};

export default SocialIconInstagram;