import React from "react";
import PropTypes from "prop-types";
import Hero, { HeroCopy } from "components/common/Hero";
import classNames from "utils/classNames";

// Shared components
import StatsCallout from "components/callouts/StatsCallout";
import SpecChartCallout from "components/callouts/SpecChartCallout";
import TwoColumnTextImage from "components/callouts/TwoColumnTextImage";
import EfficiencyCallout from "components/callouts/EfficiencyCallout";
import SpecificationsCallout from "components/callouts/SpecificationsCallout";
import Fundcanna from "components/callouts/Fundcanna";

function Home() {
  return (
    <div className={classNames.page("home")}>
      <Hero bgImg="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/Lampbackground-1-v1.jpg">
        <HeroCopy
          title={`Save 35%\non energy\nVS HPS.`}
          subtitle="Broad spectrum lighting that fits any budget."
        />
      </Hero>
      <SpecChartCallout
        src={`https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/ComparisonChart-broad-spectrum.png`}
      />
      <TwoColumnTextImage
        heading={`<em>Healthier, more potent</em>, faster growing crop.`}
        subheading={`<em>14%</em> more red <em>102%</em> more far red <em>100%</em> MORE UV`}
        bodyCopy={`<p> gGRO™ Ceramic Metal Halide grow lights provide a wider, more even spectrum than HPS (High Pressure Sodium). This means better coverage for plants, including a greater amount of blue, red, white and UV light. This facilitates faster growth, more overall yield, and better THC concentration. In short, significantly better grams/watt. All with less heat, significantly cutting cooling costs during the summer.</p>`}
        asset={{
          url: "https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/SpectrumChart-healthier-crop.png",
          title: "ggro comparison to hps lights",
        }}
      />
      <EfficiencyCallout
        heading={`<em>Higher frequency.</em><br />More yield.`}
        bodyCopy={`<h4><em>Real power</em> for real growers.</h4><p>Traditional HPS and most low frequency lights "blink" many times per second, delivering significantly less PPFD to your plants. gGRO CMH high frequency lights provide more reliable consistent energy per second, <em>significantly increasing flower yield.</em></p><h4>MORE GRAMS PER WATT</h4><p><em>gGRO</em> delivers the ideal photosynthetic photon flux density (PPFD) to your plants. By delivering a wider range of UV and IR light, plants under gGRO lights have more THC from the UV and grow faster from the IR. Meaning gGRO™ CMH delivers more energy per dollar during all growing phases.</p>`}
      />
      <StatsCallout
        heading={`The bottom-line <em>benefits of gGRO</em><wbr> lighting over our competitors.`}
        stats={[
          { value: "35%", label: "energy savings" },
          { value: "20%", label: "broad-spectrum rays" },
          { value: "14%", label: "greater efficiency<wbr> in red" },
          {
            value: "102%",
            label: `greater efficiency in <abbr title="far-red">FR</abbr>`,
            subtext: `over other <abbr title="ceramic metal halide">CMH</abbr> sources`,
          },
        ]}
      />
      <SpecificationsCallout />
      <Fundcanna />
    </div>
  );
}

Home.propTypes = {
  data: PropTypes.object,
};

export default Home;
