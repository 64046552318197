import { GraphQLClient } from "graphql-request";

const GRAPHQL_API_ENDPOINT = process.env.GRAPHQL_API_ENDPOINT ?? "/graphql/api";

export default async function request(query, variables) {
  const client = new GraphQLClient(GRAPHQL_API_ENDPOINT, {
    headers: {
      Authorization: "Bearer 1-nux2uqvVBIvnATBQQoXVW7fIYhT04v",
    },
  });

  return client.request(query, variables);
}
