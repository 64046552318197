import React from 'react';

function LogoHorizontalFullColorWhite(props) {
  return (
    <svg
      id="logo-horizontal-full-color-white_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 230.2 34.54"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path fill="#FFFFFF" d="M21.03,24.02c-1.56,0-2.78-0.32-3.68-0.97c-0.9-0.65-1.54-1.56-1.92-2.74c-0.38-1.18-0.57-2.53-0.57-4.07V8.69
		c0-1.59,0.2-2.97,0.61-4.14c0.41-1.17,1.11-2.07,2.1-2.71c0.99-0.64,2.34-0.96,4.04-0.96c1.67,0,2.98,0.28,3.93,0.83
		c0.95,0.56,1.63,1.32,2.04,2.31C27.99,5,28.2,6.15,28.2,7.47v1.44h-4.86V7.08c0-0.48-0.03-0.93-0.1-1.33
		c-0.07-0.41-0.21-0.74-0.44-0.99s-0.61-0.38-1.12-0.38c-0.54,0-0.93,0.14-1.17,0.43c-0.24,0.29-0.39,0.64-0.46,1.07
		c-0.06,0.43-0.1,0.87-0.1,1.33v10.42c0,0.5,0.05,0.97,0.15,1.4c0.1,0.44,0.29,0.79,0.56,1.06c0.27,0.27,0.65,0.4,1.15,0.4
		c0.52,0,0.92-0.14,1.19-0.42s0.47-0.64,0.58-1.08c0.11-0.44,0.17-0.92,0.17-1.42v-2.64h-2.03v-2.92h6.5v11.67h-3.28l-0.28-1.86
		c-0.3,0.63-0.73,1.15-1.31,1.57C22.79,23.82,22.01,24.02,21.03,24.02z"/>
        <path fill="#FFFFFF" d="M31.34,23.69V1.19h6.19c1.52,0,2.83,0.17,3.94,0.5c1.11,0.33,1.98,0.93,2.6,1.79C44.69,4.34,45,5.56,45,7.13
		c0,0.93-0.07,1.75-0.22,2.47c-0.15,0.72-0.42,1.35-0.82,1.88c-0.4,0.53-0.97,0.97-1.71,1.32l3.08,10.89H40.2l-2.47-10.08h-1.42
		v10.08H31.34z M36.31,10.75h1.39c0.7,0,1.25-0.12,1.64-0.38c0.39-0.25,0.66-0.61,0.82-1.08c0.16-0.47,0.24-1.04,0.24-1.71
		c0-0.96-0.18-1.71-0.53-2.25c-0.35-0.54-1-0.81-1.94-0.81h-1.61V10.75z"/>
        <path fill="#FFFFFF" d="M54.23,24.02c-1.69,0-3.02-0.3-4-0.89c-0.98-0.59-1.69-1.44-2.11-2.56c-0.43-1.11-0.64-2.43-0.64-3.97V8.25
		c0-1.56,0.21-2.88,0.64-3.97c0.43-1.09,1.13-1.93,2.11-2.51c0.98-0.58,2.31-0.88,4-0.88c1.7,0,3.05,0.29,4.04,0.88
		s1.7,1.42,2.12,2.51c0.43,1.09,0.64,2.42,0.64,3.97v8.36c0,1.54-0.21,2.86-0.64,3.97c-0.43,1.11-1.13,1.96-2.12,2.56
		S55.93,24.02,54.23,24.02z M54.23,20.5c0.52,0,0.9-0.13,1.14-0.39c0.24-0.26,0.4-0.59,0.47-1c0.07-0.41,0.11-0.82,0.11-1.25V7.02
		c0-0.44-0.04-0.87-0.11-1.26c-0.07-0.4-0.23-0.73-0.47-0.99c-0.24-0.26-0.62-0.39-1.14-0.39c-0.48,0-0.84,0.13-1.08,0.39
		c-0.24,0.26-0.4,0.59-0.47,0.99c-0.07,0.4-0.11,0.82-0.11,1.26v10.83c0,0.43,0.03,0.84,0.1,1.25c0.06,0.41,0.21,0.74,0.44,1
		C53.33,20.37,53.71,20.5,54.23,20.5z"/>
        <path fill="#FFFFFF" d="M63.23,5.08V0.83h-0.77V0h2.65v0.83h-0.76v4.25H63.23z"/>
        <path fill="#FFFFFF" d="M66.4,5.08L66.48,0h1.2l0.56,2.95L68.85,0h1.16l0.09,5.08h-0.8L69.21,1.7l-0.59,3.38h-0.71l-0.63-3.41
		l-0.08,3.41H66.4z"/>
        <path fill="#FFFFFF" d="M77.31,23.69V1.19h3.14v9.72h5.78V1.19h3.14v22.5h-3.14V13.16h-5.78v10.53H77.31z"/>
        <path fill="#FFFFFF" d="M98.64,23.94c-1.52,0-2.73-0.3-3.64-0.89c-0.91-0.59-1.56-1.44-1.94-2.54c-0.39-1.1-0.58-2.39-0.58-3.88V8.11
		c0-1.48,0.2-2.75,0.6-3.82c0.4-1.07,1.05-1.88,1.94-2.45C95.92,1.28,97.12,1,98.64,1c1.52,0,2.72,0.29,3.61,0.86
		c0.89,0.57,1.53,1.39,1.93,2.44c0.4,1.06,0.6,2.32,0.6,3.81v8.56c0,1.46-0.2,2.74-0.6,3.83c-0.4,1.09-1.04,1.94-1.93,2.54
		C101.36,23.64,100.16,23.94,98.64,23.94z M98.64,21.47c0.83,0,1.46-0.17,1.89-0.51s0.71-0.82,0.86-1.44
		c0.15-0.62,0.22-1.35,0.22-2.18V7.47c0-0.83-0.07-1.55-0.22-2.15c-0.15-0.6-0.44-1.06-0.86-1.39s-1.06-0.49-1.89-0.49
		s-1.47,0.16-1.9,0.49c-0.44,0.32-0.73,0.79-0.88,1.39c-0.15,0.6-0.22,1.32-0.22,2.15v9.86c0,0.83,0.07,1.56,0.22,2.18
		c0.15,0.62,0.44,1.1,0.88,1.44C97.17,21.3,97.81,21.47,98.64,21.47z"/>
        <path fill="#FFFFFF" d="M107.86,23.69V1.19h4.53c1.5,0,2.74,0.2,3.72,0.6c0.98,0.4,1.71,1.04,2.18,1.92c0.47,0.88,0.71,2.03,0.71,3.46
		c0,0.87-0.09,1.66-0.28,2.38c-0.19,0.71-0.48,1.32-0.89,1.81s-0.94,0.85-1.61,1.07l3.19,11.28h-3.03l-2.94-10.56H111v10.56H107.86z
		 M111,10.88h1.19c0.89,0,1.61-0.11,2.17-0.33c0.56-0.22,0.96-0.6,1.22-1.14s0.39-1.29,0.39-2.25c0-1.32-0.24-2.26-0.72-2.85
		s-1.44-0.88-2.86-0.88H111V10.88z"/>
        <path fill="#FFFFFF" d="M124.76,23.69V3.52h-3.81V1.19h10.64v2.33h-3.69v20.17H124.76z"/>
        <path fill="#FFFFFF" d="M133.92,23.69V1.19h3.08v22.5H133.92z"/>
        <path fill="#FFFFFF" d="M146.28,23.94c-1.59,0-2.83-0.33-3.71-1c-0.88-0.67-1.49-1.56-1.83-2.69s-0.51-2.39-0.51-3.78V8.52
		c0-1.52,0.17-2.84,0.51-3.97s0.95-2,1.83-2.62C143.45,1.3,144.69,1,146.28,1c1.41,0,2.52,0.26,3.35,0.78
		c0.82,0.52,1.42,1.26,1.78,2.22c0.36,0.96,0.54,2.12,0.54,3.47v1.78h-2.97V7.66c0-0.83-0.04-1.57-0.12-2.21
		c-0.08-0.64-0.31-1.13-0.68-1.49c-0.37-0.35-0.99-0.53-1.86-0.53c-0.89,0-1.54,0.19-1.94,0.57c-0.41,0.38-0.67,0.92-0.79,1.61
		s-0.18,1.5-0.18,2.43v8.92c0,1.13,0.09,2.02,0.28,2.68s0.49,1.12,0.92,1.4c0.43,0.28,1,0.42,1.72,0.42c0.85,0,1.46-0.19,1.83-0.57
		c0.37-0.38,0.6-0.9,0.69-1.57s0.14-1.44,0.14-2.33v-1.67h2.97V17c0,1.37-0.17,2.58-0.5,3.62s-0.91,1.86-1.72,2.44
		C148.91,23.65,147.76,23.94,146.28,23.94z"/>
        <path fill="#FFFFFF" d="M160.76,23.94c-1.63,0-2.88-0.32-3.75-0.97c-0.87-0.65-1.46-1.56-1.78-2.74c-0.31-1.18-0.47-2.55-0.47-4.12
		V1.19h3v15.06c0,0.96,0.06,1.84,0.19,2.64c0.13,0.8,0.41,1.43,0.85,1.89c0.43,0.46,1.09,0.69,1.96,0.69c0.89,0,1.55-0.23,1.97-0.69
		c0.43-0.46,0.7-1.09,0.83-1.89c0.13-0.8,0.19-1.68,0.19-2.64V1.19h2.97v14.92c0,1.57-0.16,2.95-0.47,4.12
		c-0.31,1.18-0.9,2.09-1.76,2.74C163.63,23.62,162.39,23.94,160.76,23.94z"/>
        <path fill="#FFFFFF" d="M170.03,23.69V1.19h3.14v20.25h5.75v2.25H170.03z"/>
        <path fill="#FFFFFF" d="M182.92,23.69V3.52h-3.81V1.19h10.64v2.33h-3.69v20.17H182.92z"/>
        <path fill="#FFFFFF" d="M197.73,23.94c-1.63,0-2.88-0.32-3.75-0.97c-0.87-0.65-1.46-1.56-1.78-2.74c-0.31-1.18-0.47-2.55-0.47-4.12
		V1.19h3v15.06c0,0.96,0.06,1.84,0.19,2.64c0.13,0.8,0.41,1.43,0.85,1.89c0.43,0.46,1.09,0.69,1.96,0.69c0.89,0,1.55-0.23,1.97-0.69
		c0.43-0.46,0.7-1.09,0.83-1.89c0.13-0.8,0.19-1.68,0.19-2.64V1.19h2.97v14.92c0,1.57-0.16,2.95-0.47,4.12
		c-0.31,1.18-0.9,2.09-1.76,2.74C200.6,23.62,199.36,23.94,197.73,23.94z"/>
        <path fill="#FFFFFF" d="M207.01,23.69V1.19h4.53c1.5,0,2.74,0.2,3.72,0.6c0.98,0.4,1.71,1.04,2.18,1.92c0.47,0.88,0.71,2.03,0.71,3.46
		c0,0.87-0.09,1.66-0.28,2.38c-0.19,0.71-0.48,1.32-0.89,1.81s-0.94,0.85-1.61,1.07l3.19,11.28h-3.03l-2.94-10.56h-2.44v10.56
		H207.01z M210.15,10.88h1.19c0.89,0,1.61-0.11,2.17-0.33c0.56-0.22,0.96-0.6,1.22-1.14s0.39-1.29,0.39-2.25
		c0-1.32-0.24-2.26-0.72-2.85s-1.44-0.88-2.86-0.88h-1.39V10.88z"/>
        <path fill="#FFFFFF" d="M221.42,23.69V1.19h8.72v2.33h-5.58v7.45h4.53v2.22h-4.53v8.25h5.64v2.25H221.42z"/>
      </g>
      <g>
        <g>
          <g>
            <path fill="#18cf29" d="M6.28,34.54c-1.06,0-2.07-0.17-3.03-0.51c-0.96-0.34-1.74-0.85-2.35-1.53C0.3,31.81,0,30.95,0,29.91
				c0-0.78,0.15-1.43,0.44-1.95s0.64-0.94,1.04-1.24c0.4-0.3,0.77-0.53,1.1-0.67s0.54-0.23,0.62-0.25
				c-0.08-0.02-0.25-0.09-0.51-0.19c-0.26-0.1-0.53-0.27-0.84-0.5s-0.56-0.53-0.78-0.92c-0.22-0.38-0.33-0.86-0.33-1.43
				c0-0.42,0.08-0.78,0.24-1.08c0.16-0.3,0.35-0.55,0.58-0.74c0.23-0.19,0.46-0.32,0.69-0.39c0.05-0.02,0.11-0.05,0.2-0.07
				c0.09-0.02,0.15-0.03,0.18-0.02c-0.07-0.06-0.21-0.2-0.43-0.44c-0.22-0.23-0.45-0.57-0.71-1.02c-0.26-0.45-0.48-1.03-0.66-1.74
				c-0.19-0.71-0.28-1.56-0.28-2.56c0-1.38,0.19-2.54,0.58-3.48C1.5,10.3,1.99,9.54,2.58,8.97C3.17,8.4,3.8,7.99,4.45,7.74
				c0.66-0.26,1.27-0.38,1.84-0.38c1.19,0,2.2,0.3,3.03,0.91c0.84,0.6,1.48,1.44,1.94,2.52c0.45,1.08,0.68,2.32,0.68,3.72
				c0,1.5-0.19,2.73-0.57,3.68c-0.38,0.95-0.86,1.7-1.46,2.23c-0.59,0.53-1.22,0.9-1.87,1.11c-0.66,0.2-1.26,0.31-1.8,0.31
				c-0.67,0-1.22-0.07-1.63-0.21c-0.41-0.14-0.67-0.23-0.78-0.26c-0.01-0.01-0.02-0.02-0.03-0.02c-0.01,0-0.02,0-0.04,0
				c-0.15,0-0.31,0.08-0.46,0.25c-0.16,0.17-0.24,0.4-0.24,0.71c0,0.43,0.28,0.8,0.84,1.12c0.56,0.31,1.61,0.61,3.16,0.89
				c1.78,0.31,3.16,0.86,4.15,1.65c0.99,0.78,1.48,1.98,1.48,3.58c0,0.98-0.21,1.79-0.64,2.43c-0.42,0.65-0.97,1.16-1.63,1.53
				c-0.66,0.38-1.36,0.65-2.1,0.8C7.59,34.46,6.9,34.54,6.28,34.54z M6.28,33.26c0.79,0,1.43-0.29,1.91-0.87
				c0.48-0.58,0.72-1.26,0.72-2.02c0-0.81-0.12-1.44-0.35-1.88c-0.23-0.44-0.67-0.83-1.31-1.17c-0.23-0.13-0.49-0.26-0.78-0.39
				c-0.28-0.13-0.56-0.25-0.81-0.35c-0.26-0.1-0.46-0.17-0.61-0.22c-0.23,0.12-0.47,0.32-0.71,0.62c-0.24,0.3-0.45,0.68-0.62,1.15
				c-0.17,0.47-0.25,1.04-0.25,1.72c0,0.67,0.11,1.27,0.33,1.79c0.22,0.52,0.54,0.92,0.96,1.2C5.18,33.12,5.68,33.26,6.28,33.26z
				 M6.24,20.46c0.27,0,0.51-0.13,0.74-0.39s0.41-0.81,0.56-1.65c0.15-0.84,0.22-2.12,0.22-3.85c0-1.66-0.07-2.91-0.22-3.74
				C7.4,10,7.21,9.44,6.98,9.16C6.76,8.87,6.52,8.73,6.26,8.73s-0.5,0.14-0.73,0.43c-0.23,0.28-0.42,0.85-0.57,1.7
				c-0.15,0.85-0.22,2.11-0.22,3.78c0,1.72,0.08,2.99,0.23,3.82c0.15,0.83,0.34,1.37,0.57,1.62C5.76,20.34,6,20.46,6.24,20.46z"/>
          </g>
        </g>
        <g>
          <path fill="#18cf29" d="M8.18,8.71c0.13-0.84,0.51-1.49,1.16-1.96c0.65-0.47,1.33-0.71,2.06-0.71c0.61,0,1.04,0.15,1.29,0.45
			c0.25,0.3,0.38,0.67,0.38,1.12c0,0.44-0.12,0.77-0.36,1.02c-0.24,0.24-0.54,0.36-0.91,0.36c-0.37,0-0.63-0.08-0.78-0.25
			c-0.15-0.17-0.28-0.33-0.39-0.49C10.52,8.08,10.34,8,10.07,8c-0.15,0-0.28,0.05-0.4,0.15c-0.12,0.1-0.18,0.29-0.18,0.58L8.18,8.71
			z"/>
        </g>
      </g>
    </svg>
  );
}

export default LogoHorizontalFullColorWhite;
