import React, { useRef, useEffect, useReducer } from "react";
// import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import _debounce from "lodash/debounce";
import bem from "easy-bem";
import { detailedDiff } from "deep-object-diff";
import Burger from "components/global/Burger";
import SocialIconInstagram from "components/svg/SocialIconInstagram";
import SocialIconFacebook from "components/svg/SocialIconFacebook";
import SocialIconTwitter from "components/svg/SocialIconTwitter";
import SocialIconLinkedin from "components/svg/SocialIconLinkedin";
// import Logo from "components/icons/Logo";
import "./nav-bar.scss";

// 76.5rem
// const menuBreakpoint = 1366;
// const burgerScrollTrigger = 50;

const links = [
  // { label: "Home", to: '/'},
  { label: "About", external: false, to: "/about" },
  { label: "HPS", external: false, to: "/" },
  { label: "LED", external: false, to: "/cmh-vs-led" },
  { label: "Fixtures", external: true, to: "https://store.ggrohorticulture.com/products/ggro-630w-cmh-4200k-solar-bundle", },
  { label: "Accessories", external: true, to: "https://store.ggrohorticulture.com/collections/accessories/accessories", },
  { label: "Knowledge Center", external: false, to: "/knowledge-center" },
  { label: "Contact", external: false, to: "/contact" },
  // { label: "Support", external: false, to: "/support" },
];

function reducer(state, payload) {
  const newState = {
    ...state,
    ...payload,
  };

  if (newState.mobile) {
    newState.burger = true;
  } else { 
    newState.open = false;
    newState.close = false;
    newState.burger = false;
  }

  // const preState = newState;

  // if (
  ////    !newState.mobile &&
  ////    newState.scroll < burgerScrollTrigger &&
  //   newState.burger
  // ) {
  ////    newState.burger = false;
  ////  } else if (newState.scroll >= burgerScrollTrigger && !newState.burger) {
  //   newState.burger = true;
  // }

  return newState;
}

function NavBar() {
  const scrollRef = useRef();

  const [state, dispatch] = useReducer(reducer, {}, () => {
    const mobile = true;//window.innerWidth < menuBreakpoint;

    return {
      mobile: mobile,
      open: false,
      close: false,
      burger: mobile,
    };
  });

  const updateState = (payload) => {
    if (!payload.scroll) {
      payload.scroll = scrollRef.current;
    }

    const changes = detailedDiff(state, payload);
    if (
      Object.keys(changes.added).length > 0 ||
      Object.keys(changes.updated).length > 0
    ) {
      dispatch(payload);
    }
  };

  const handleScroll = _debounce(() => {
    //   scrollRef.current = window.scrollY;
    const newBurgerState = true; //!state.mobile && window.scrollY < burgerScrollTrigger ? false : true;
    if (newBurgerState !== state.burger) {
      updateState({ burger: newBurgerState, scroll: window.scrollY });
    }
  },
  250,
  { leading: true, trailing: true });

  const handleResize = _debounce(
    () => {
      updateState({ mobile: true });//window.innerWidth < menuBreakpoint });
    },
    250,
    { leading: true, trailing: true }
  );

  const unBindResizeListener = () => {
    return window.removeEventListener("resize", handleResize);
  };

  const bindResizeListener = () => {
    return window.addEventListener("resize", handleResize);
  };

  const unBindScrollListener = () => {
    return window.removeEventListener("scroll", handleScroll);
  };

  const bindScrollListener = () => {
    return window.addEventListener("scroll", handleScroll);
  };

  const getMenuContainerClasses = () => {
    const { open, close, burger, mobile } = state;
    const b = bem("nav-bar");
    const classes = b({ open, close, burger, desktop: !mobile });
    //    console.log('MENU ____ getMenuContainerClasses ', classes);
    return classes;
  };

  const openMenu = () => {
    updateState({ open: true });
    updateState({ close: false });
  };

  const closeMenu = () => {
    updateState({ open: false });
    updateState({ close: true });
  };

  const handleBurgerClick = () => {
    
    // Make Logo (bug) fixed when mobile menu is toggled to ensure it's visible
    const bug = document.querySelector('.bug');
    bug.classList.toggle('bug-fixed');

    // console.log('handleBurgerClick', state);
    // if (!state.mobile) {
    //   window.scrollTo(0, 0);
    // } else
    if (state.open) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  useEffect(() => {
    // console.log('Menu useEffect 1');
    bindScrollListener();
    bindResizeListener();
    return () => {
      unBindScrollListener();
      unBindResizeListener();
    };
  });

  const { burger, close, open, } = state;
  const b = bem("site-nav");

  return (
    <>
      <nav className={getMenuContainerClasses()} role="navigation">
        <NavLink to="/" className="bug">
          <img
            className="ggro-logo-lamp"
            src="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/LogoPng.png"
            alt="ggro logo"
          />
        </NavLink>
        <ul className={b()}>
          {links.map((link, index) => (
            <li key={`${b("item")}-${index}`} className={b("item")}>
              {link.external ? (
                <a href={link.to} className={b("link")} onClick={() => { handleBurgerClick(); }}>
                  {link.label}
                </a> 
              ) : (
                <NavLink
                  to={link.to}
                  className={({ isActive }) => b("link", { active: isActive })}
                  onClick={() => { handleBurgerClick(); }}
                >
                  {link.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
        <ul className="site-nav__social-ul">
          <li className="site-nav__social-li">
            <a className="site-nav__social-a" href="https://www.facebook.com/gGROHorticulture/"><SocialIconFacebook /></a>
          </li>
          <li className="site-nav__social-li">
            <a className="site-nav__social-a" href="https://www.instagram.com/ggro_horticulture/"><SocialIconInstagram /></a>
          </li>
          <li className="site-nav__social-li">
            <a className="site-nav__social-a" href="https://twitter.com/ggrohort"><SocialIconTwitter /></a>
          </li>
          <li className="site-nav__social-li">
            <a className="site-nav__social-a" href="https://www.linkedin.com/company/ggrohorticulture/"><SocialIconLinkedin /></a>
          </li>
        </ul>
      </nav>
      {burger && <Burger isOpen={open} isClose={close} onClick={handleBurgerClick} />}
    </>
  );
}

NavBar.propTypes = {};

export default NavBar;
