import React from 'react';
import PropTypes from "prop-types";

function SocialIconTwitter({color}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72.918" height="72.919" viewBox="0 0 72.918 72.919" aria-hidden="true" focusable="false"  role="presentation">
      <title>Twitter</title>
      <path id="social-icon-twitter" d="M159.7,10.679a36.46,36.46,0,1,0,0,51.563,36.461,36.461,0,0,0,0-51.563m-6.931,16.79c1.557,21.677-21.8,34.849-40.717,24.072,0,0,9.341.479,13.293-4.072,0,0-5.747.479-8.623-6.467a5.778,5.778,0,0,0,4.312-.239,9.347,9.347,0,0,1-7.3-9.1,5.907,5.907,0,0,0,4.551,1.078,9.187,9.187,0,0,1-3.234-12.454s7.905,10.057,19.282,9.7l0,0a9.486,9.486,0,0,1,16.287-8.263,23.971,23.971,0,0,0,5.629-2.516,10.366,10.366,0,0,1-4.191,5.271,17.84,17.84,0,0,0,5.15-1.436,10.015,10.015,0,0,1-4.432,4.432" transform="translate(-97.462 -0.001)" fill="#fff"/>
    </svg>
  );
}

SocialIconTwitter.defaultProps = {
  color: '#FFFFFF'
};

SocialIconTwitter.propTypes = {
  color: PropTypes.string,
};

export default SocialIconTwitter;