import React from 'react';
import PropTypes from "prop-types";

function SocialIconLink({color}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72.19" height="72.19" viewBox="0 0 72.19 72.19">
      <title>Email</title>
      <path id="social-icon-link" data-name="Exclusion 1" d="M36.095,72.19A36.1,36.1,0,0,1,10.571,10.571,36.095,36.095,0,1,1,61.617,61.617,35.855,35.855,0,0,1,36.095,72.19ZM32.857,27.141a10.24,10.24,0,0,0-7.289,3.019l-7.287,7.287A10.308,10.308,0,0,0,32.858,52.024L38.381,46.5a13.446,13.446,0,0,1-1.954.144,12.693,12.693,0,0,1-4.622-.858l-2.593,2.595a5.155,5.155,0,0,1-7.29-7.292l7.29-7.287a5.114,5.114,0,0,1,8.562,2.185l.108-.061a1.9,1.9,0,0,0,.439-.3l3.389-3.387a10.027,10.027,0,0,0-1.566-2.079A10.239,10.239,0,0,0,32.857,27.141ZM31.584,35.26h0l-.119.068a1.868,1.868,0,0,0-.43.3l-3.387,3.389A10.264,10.264,0,0,0,43.788,41.09L51.08,33.8A10.308,10.308,0,0,0,36.5,19.226l-5.523,5.523a13.478,13.478,0,0,1,1.95-.143,12.676,12.676,0,0,1,4.626.859l2.592-2.595a5.154,5.154,0,0,1,7.288,7.29l-7.288,7.287a5.16,5.16,0,0,1-7.287,0,5.053,5.053,0,0,1-1.274-2.187Z" transform="translate(0 0)" fill="#fff"/>
    </svg>
  );
}

SocialIconLink.defaultProps = {
  color: '#FFFFFF'
};

SocialIconLink.propTypes = {
  color: PropTypes.string,
};

export default SocialIconLink;