import React from "react";
import PropTypes from "prop-types";
import ContentBlockLeadCopy from "./ContentBlockLeadCopy";
import ContentBlockLeadIn from "./ContentBlockLeadIn";
import ContentBlockBodyCopy from "./ContentBlockBodyCopy";
import ContentBlockContentImage from "./ContentBlockContentImage";
import ContentBlockStat from "./ContentBlockStat";
import ContentBlockTwoColumns from "./ContentBlockTwoColumns";
import ContentBlockVideo from "./ContentBlockVideo";

import "./content-block.scss";

function DynamicContentBlock(props) {
  
  const { typeHandle } = props;
  
  const getComponentByTypeHandle = (typeHandle) => {
    const contentBlockComponentMap = {
      leadCopy: ContentBlockLeadCopy,
      leadIn: ContentBlockLeadIn,
      bodyCopy: ContentBlockBodyCopy,
      contentImage: ContentBlockContentImage,
      stat: ContentBlockStat,
      twoColumns: ContentBlockTwoColumns,
      video: ContentBlockVideo,
    };

    return contentBlockComponentMap[typeHandle];
  };
  const ContentBlock = getComponentByTypeHandle(typeHandle);
  return <ContentBlock {...props} />;
}

DynamicContentBlock.propTypes = {
  typeHandle: PropTypes.string.isRequired,
};

export default DynamicContentBlock;
