import React, { useRef, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import bem from "easy-bem";
import ContactIcon from "components/svg/ContactIcon";
import BackToTopIcon from "components/svg/BackToTopIcon";

import "./contact-cta.scss";

function ContactCta() {
  const ref = useRef();
  const intervalRef = useRef();
  const backToTopRef = useRef();

  useEffect(() => {
    
    const hoverAnimation = new Animation(
      new KeyframeEffect(
        ref.current,
        [
          { transform: `scale(1)` },
          { offset: 0.7, transform: `scale(1.1)` },
          { transform: `scale(1)` },
        ],
        {
          duration: 700,
          easing: "ease",
          iterations: Infinity,
        }
      ),
      document.timeline
    );

    const onMouseEnter = () => hoverAnimation.play();
    const onMouseLeave = () => hoverAnimation.pause();

    ref.current.addEventListener("mouseenter", onMouseEnter);
    ref.current.addEventListener("mouseleave", onMouseLeave);

    intervalRef.current = setInterval(() => {
      hoverAnimation.play();
    }, 10000);

    return () => {
      ref.current.addEventListener("mouseenter", onMouseEnter);
      ref.current.addEventListener("mouseleave", onMouseLeave);
      clearInterval(intervalRef.current);
    };

  }, []);

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <>
      <Link ref={backToTopRef} to="#" onClick={ scrollToTop } className={bem("back-to-top-cta")()}>
        <BackToTopIcon />
      </Link>
      <Link ref={ref} to="/contact#contact" className={bem("contact-cta")()}>
        <ContactIcon />
      </Link>
    </>
  );
}

ContactCta.propTypes = {};

export default ContactCta;
