import React from "react";
import PropTypes from "prop-types";
import Hero, { HeroCopy } from "components/common/Hero";
import PageSection from "components/common/PageSection";
import classNames from "utils/classNames";
import "./support.scss";
function SupportPage() {
  return (
    <div className={classNames.page("support")}>
      <Hero bgImg="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/support-hero.png">
        <HeroCopy title={`RETURN AND \nEXCHANGE POLICY`} />
      </Hero>
      <PageSection modifiers={{ "body-copy": true }}>
        <div className="page-content-grid">
          <div className="grid-item">
            <p>
              <strong>The 365-day Policy for gGRO Fixtures</strong>: Our policy
              is clear, if you are not completely satisfied with your purchase,
              simply return your item within One Year of receipt for a full
              refund. Our 365-day money back guarantee, allows customers to shop
              and grow in confidence.
            </p>
            <p>
              To fulfill your 365-day money back guarantee, please call us at
              (704) 777-7844 or email at orders@ggrohorticulture.com to obtain a
              return authorization. All returned items must be in the original
              packaging and still be in as-sold-condition without major damage
              or unusual signs of wear and tear. All returns will be inspected
              prior to credit being issued. Items returned must be accompanied
              with a copy of the return authorization inside the box.
            </p>
            <p>
              You must contact gGRO Horticulture™ to return your gGRO fixture
              and request a refund at any time on or before the 365th day after
              purchase.
            </p>
            <p>
              <strong>Accessories</strong>: If you are not satisfied with your
              purchase, simply return your item within 30 days of receipt for a
              refund. Please call us at (704) 777-7844 or email at
              orders@ggrohorticulture.com to obtain a return authorization.
            </p>
          </div>
          <div className="grid-item">
            <p>
              All returned items must be in the original packaging and not show
              signs of major damage or unusual signs of wear and tear. All
              returns will be inspected prior to credit being issued. Items
              returned must be accompanied with a copy of the return
              authorization inside the box.
            </p>
            <p>
              Please allow a minimum of 10 days for us to receive and process
              your return. You can expect a refund within 30 days of when we
              receive your return. We'll notify you via email once we've
              processed the returned product(s). We'll credit or refund the
              value of the merchandise total. gGRO Horticulture does not credit
              shipping costs for returned orders. Refunds will be given in the
              same form of payment as the original purchase.
            </p>
            <p>
              Please make sure to wrap the package securely and send it to the
              following address:
            </p>
            <p>
              gGRO Horticulture
              <br />
              Attn: Online Returns
              <br />
              801 S Cedar St
              <br />
              Charlotte, NC 28208
            </p>
          </div>
        </div>
        <figure className="warranty-seal">
          <img
            src="https://d244yw68jia32n.cloudfront.net/uploads/common/ggro-guarantee-seal_480x480.png"
            alt="ggro guarantee seal"
          />
        </figure>
      </PageSection>
    </div>
  );
}

SupportPage.propTypes = {
  data: PropTypes.object,
};

export default SupportPage;
