import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
// import {useAppContext} from './context';
import useScrollToHash from 'hooks/useScrollToHash';
import PageMetaQuery from "gql/queries/pageMeta.gql";
import fetchGraphQL from "gql/utils";

function DocumentHead() 
{
  
  const location = useLocation();
  const [meta, setMeta] = useState(null);
  const [uri, setUri] = useState(() => location.pathname.replace("/", ""));

  const loadPageMeta = async (uri) => {
    const resp = await fetchGraphQL(PageMetaQuery, { uri: uri });
    Object.entries(resp.seomatic).forEach(([key, value]) => {
      resp.seomatic[key] = JSON.parse(value);
    });
    return resp;
  };

  const isHomePage = () => location.pathname === "/";

  useScrollToHash();
  
  useEffect(async () => {
    // don't run if uri not set or not on homepage
    if (uri === "" && !isHomePage()) {
      return;
    }

    // Handle page jump to top before content update
    window.scrollTo({ top: 0, left: 0, behavior: 'instant'});

    const resp = await loadPageMeta(uri);

    const newMeta = {
      title: resp.seomatic.metaTitleContainer.title.title,
      meta: [],
      link: [],
    };
    Object.values(resp.seomatic.metaTagContainer).forEach((entry) => {
      if (typeof entry === "object" && typeof entry.content !== "undefined") {
        newMeta.meta.push(entry);
      }
    });
    Object.values(resp.seomatic.metaLinkContainer).forEach((entry) => {
      if (typeof entry === "object" && typeof entry.href !== "undefined") {
        newMeta.link.push(entry);
      }
    });
    //console.log("DocumentHead :: Updating Meta", uri, newMeta);
    setMeta(newMeta);
    
  }, [uri]);

  useEffect(() => {
    const newURI = location.pathname.replace("/", "");
    if (newURI !== uri) {
      setUri(newURI);
    }
  }, [location]);

  return <Helmet async={true} {...meta} />;
}

export default DocumentHead;
