import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Hero, { HeroCopy } from "components/common/Hero";
import classNames from "utils/classNames";
import fetchGraphQL from "gql/utils";
import BlogEntriesQuery from "gql/queries/blogEntries.gql";
import useBlogTags from "hooks/useBlogTags";

import "./blog-shared.scss";
import "./blog-home.scss";

function KnowledgeCenterLanding() {
  const pageRef = useRef(0);

  const [articles, setArticles] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const { activeTags, TagFilterForm, TagFilterSelector, TagFilterSelectorPill, TagFilterList } = useBlogTags({
    uri: "knowledge-center*",
  });
  const articleRefs = useRef([]);

  const blogArticleUp = (articles) => {
    let blogArticleGallery = [[]];
    let blogArticleGalleryMax = 3;
    let blogArticleGalleryGroup = 0;

    articles.map((_article, _articleItem) => {
      if (_articleItem !== 0 && _articleItem % blogArticleGalleryMax === 0) {
        blogArticleGalleryGroup++;
        blogArticleGallery[blogArticleGalleryGroup] = [];
      }

      blogArticleGallery[blogArticleGalleryGroup].push(_article);
    });

    // console.log('blogArticleGallery', blogArticleGallery, 'blogArticleGalleryGroup', blogArticleGalleryGroup);
    return blogArticleGallery;
  };

  const loadArticles = async (current = pageRef.current) => {
    
    const resp = await fetchGraphQL(BlogEntriesQuery, {
      articleTags: activeTags,
      limit: 10,
      offset: current * 5,
    });

    const articleData = [];

    setArticleCount(resp.entryCount);
    return loadArticlesMatchAllTags(resp.entries, articleData);
  };

  const loadArticlesMatchAllTags = ( _articles, _articlesList ) => {

    _articles.map((row) => {
      
      let activeTagsMatch = [];

      activeTags.forEach( ( _activeTagId ) => {
        
        row.articleTags.forEach( ( _articleTagId ) => {
          if ( parseInt( _activeTagId ) === parseInt( _articleTagId.id ) ) {
            activeTagsMatch.push(_activeTagId);
            return;
          }
        });
        
      });

      if ( activeTagsMatch.length === activeTags.length ) _articlesList.push({
        id: row.id,
        postDate: row.postDate,
        url: `/${row.uri}`,
        cardImage: row.cardImage[0],
        title: row.title,
        excerpt: row.articleExcerpt,
        tags: row.articleTags,
      });
    
    });
    
    return _articlesList;

  };

  const articlesGet = () =>
  {
    return document.querySelectorAll('.blog--gallery-article');
  };

  const hideArticles = async () => {

    //console.log(`HIDE Articles: ${articleRefs.current}`);
    
    articlesGet().forEach((_article, _articleIndex) => {

      let delay = setTimeout(() => {
        _article.classList.remove('blog--gallery-article-in');
        _article.classList.add('blog--gallery-article-out');
        clearTimeout(delay);
      }, _articleIndex * 120);

    });

  };

  const showArticles = async () => {

    //console.log(`SHOW Articles: ${articleRefs.current}`);

    articlesGet().forEach((_article, _articleIndex) => {

      let delay = setTimeout(() => {
        _article.classList.remove('blog--gallery-article-out');
        _article.classList.add('blog--gallery-article-in');
        clearTimeout(delay);
      }, _articleIndex * 120);

    });
  };

  const handleLoadMore = async () => {
    pageRef.current++;
    const articleData = await loadArticles();
    setArticles([...articles, ...articleData]);
  };

  // reset pager and load articles when activeTags change
  useEffect(async () => {
    pageRef.current = 0;
    const articleData = await loadArticles();
    setArticles(articleData);
    await showArticles();
  }, [activeTags]);

  return (
    <>
      <div className={classNames.page("knowledgecenter")}>
        <Hero
          modifiers={{ knowledgcenter: true }}
          bgImg={"/static/blog/gGRO-blog-landing-background.jpg"}
        >
          <HeroCopy
            title={`Gro Your<br /> Knowledge.`}
            subtitle={`Knowledge saves you time, money, and power. The gGRO Knowledge Center is a resource we offer to all growers.`} // Here, you'll find the answers to any technical questions you may have about gGRO CMH set-ups, as well as general grow light FAQs.`}
          />
        </Hero>

        <section className='grid grid-layers--lg blog'>
          <TagFilterSelector />
          <TagFilterSelectorPill submitCallback={hideArticles} />
          <TagFilterForm submitCallback={hideArticles} resetCallback={hideArticles} />
          <div className='blog--gallery'>
            {articles && blogArticleUp(articles).map((_item, _itemIndex) => (
              <>
                {_item.map((_entry, _entryIndex) => (
                  <article key={`article-${_itemIndex}-${_entry.id}`} ref={articleRefs.current[_entry.id]} className={`blog--gallery-article blog--gallery-article-${_entryIndex}`}>
                    <p className={`blog--article-tag-filter-list`}><TagFilterList tagged={_entry.tags} linked={true} /></p>
                    <Link to={_entry.url}>
                      <picture className="picture-object-fit-cover blog--gallery-article-image z-1">
                        <img
                          src={`${_entry.cardImage.url}`}
                          alt={`${_entry.cardImage.title}`}
                        />
                      </picture>
                    </Link>
                    <header className="blog--gallery-header z-2 grid">
                      <div className="blog--gallery-header__inner">
                        <h2 className="blog--gallery-header-h2">
                          <Link to={_entry.url}>
                            {_entry.title}
                          </Link>
                        </h2>
                        <p className="blog--gallery-article-excerpt">
                          <span className='blog--gallery-article-excerpt-text'>{_entry.excerpt}</span>
                          <Link to={_entry.url} className="blog--gallery-article-button">
                            Read More
                          </Link>
                        </p>
                      </div>
                    </header>
                  </article>
                ))}
              </>
            ))}
          </div>
        </section>

        <div className='blog--gallery-footer'>

          {articles.length < articleCount && (
            <p><button className="blog--button-green" onClick={handleLoadMore}>
              Load More
            </button></p>
          )}
          
          <p><Link to={'/contact#contact'} className={'blog--button-white'}>Contact for a test grow</Link></p>
        </div>
      </div>
    </>
  );
}

KnowledgeCenterLanding.propTypes = {
  data: PropTypes.object,
};

export default KnowledgeCenterLanding;
