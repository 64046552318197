import React from "react";
import PropTypes from "prop-types";
import DynamicContentBlock from "components/DynamicContentBlock";
import "./content-block-twoColumns.scss";

function ContentBlockTwoColumns({ children }) {
  return (
    <div className="content-block content-block-twoColumns">
      {children.map((contentBlock) => (
        <DynamicContentBlock
          key={`content-block-${contentBlock.id}`}
          {...contentBlock}
        />
      ))}
    </div>
  );
}

ContentBlockTwoColumns.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ContentBlockTwoColumns;
