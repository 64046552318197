import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import PageSection from "components/common/PageSection";
import Stat from "components/common/Stat";
import "./stat-callout.scss";

function StatsCallout({ heading, stats, bgImg }) {
  return (
    <PageSection
      modifiers={{ "body-copy": true, callout: "stats" }}
      appendClassName={`p-4`}
      bgImg={bgImg}
    >
      <div className="stats-callout text-center">
        {heading && (
          <Interweave tagName="h2" className="h3" content={heading} />
        )}
        <div className="flex-responsive--fit stat-list">
          {stats.map((item, index) => (
            <div
              className="stat-list__item"
              key={`stat-list__item-${(item, index)}`}
            >
              <Stat {...item} fluid={false} />
            </div>
          ))}
        </div>
      </div>
    </PageSection>
  );
}

StatsCallout.defaultProps = {
  bgImg:
    "https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/g-GRO-reflector.jpg",
};

StatsCallout.propTypes = {
  stats: PropTypes.array.isRequired,
  heading: PropTypes.string,
  bgImg: PropTypes.string,
};

export default StatsCallout;
