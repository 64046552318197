import React from "react";
import PropTypes from "prop-types";
import Stat from "components/common/Stat";
import "./content-block-stat.scss";

function ContentBlockStat({ statLabel, statValue }) {
  return (
    <div className="content-block content-block-stat">
      <Stat value={statValue} label={statLabel} labelSize="lg" />
    </div>
  );
}

ContentBlockStat.propTypes = {
  statLabel: PropTypes.string,
  statValue: PropTypes.string,
};

export default ContentBlockStat;
