/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import bem from "easy-bem";
import "./page-section.scss";

function PageSection({
  classPrefix,
  modifiers,
  bgImg,
  appendClassName,
  children,
}) {
  const b = bem(classPrefix);
  const styles = {};
  if (bgImg) {
    styles.backgroundImage = `url('${bgImg}')`;
    appendClassName += " bg-img";
  }

  return (
    <section
      className={b(modifiers) + (appendClassName ? " " + appendClassName : "")}
      style={styles}
    >
      <div className={b("inner")}>{children}</div>
    </section>
  );
}

PageSection.defaultProps = {
  appendClassName: null,
  classPrefix: "page-section",
};

PageSection.propTypes = {
  classPrefix: PropTypes.string,
  appendClassName: PropTypes.string, // for additional utility classes
  bgImg: PropTypes.string,
  modifiers: PropTypes.object,
  children: PropTypes.node,
};

export default PageSection;
