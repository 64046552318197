import React from "react";
import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./content-block-video.scss";
// import ReactPlayerYouTube from 'react-player/youtube';
import ReactPlayerVimeo from 'react-player/vimeo';

function ContentBlockVideo({ 
  videoPlayerHost,
  videoPlayerUrl,
  videoPlayerGui,
  videoPlayerAspectRatioWidth,
  videoPlayerAspectRatioHeight,
  videoPlayerLoop,
  videoPlayerMuted,
}) {
  
  const deviceUserAgent = navigator.userAgent.toLowerCase();
  const deviceIsMobile = /iphone|android/i.test(deviceUserAgent);
  const deviceIsTablet = /(iphone|ipad|ipod|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(ip|ap|wp))))/.test(deviceUserAgent);

  const [videoPlayerPlaying, setVideoPlayerPlaying] = useState(false);
  const videoPlayerRef = useRef(null);
  const videoPlayerWrapperRef = useRef(null);

  let videoPlayerInPreview = !videoPlayerGui;
  let videoPlayerAutoplay = false;
  let videoPlayerVolume = 1;

  const videoPlayerReady = () => {
    //if ( !videoPlayerPlaying) setVideoPlayerPlaying(true); 
    console.log(`player playing after ready? ${videoPlayerPlaying}`);
    videoPlayerPlaybackSurfaceRef.current.classList.remove('content-block-video-playback-surface-none');
    setVideoPlayerPlaying(true);
  };
  
  const videoPlayerLightClick = () => {
    console.log('video player click');

    // Do something before player ready...

    return;
  };

  const videoPlayerPlaybackSurfaceRef = useRef(null);
  const videoPlayerPlayheadBarRef = useRef(null);
  const videoPlayerPlayheadScrubBarRef = useRef(null);
  const videoPlayerPlayheadSeekBarRef = useRef(null);
  const videoPlayerPlayheadLoadBarRef = useRef(null);

  const videoPlayerProgressSeek = (_event) => {
    let playheadX = (isNaN(_event.playedSeconds)) ? 0 : ((_event.played/_event.loaded) * 100).toFixed(2);
    videoPlayerPlayheadSeekBarRef.current.style.width = `${playheadX}%`;
    videoPlayerPlayheadScrubBarRef.current.style.left = `${videoPlayerPlayheadSeekBarRef.current.getBoundingClientRect().width}px`;
    return;
  };

  const videoPlayerProgressLoad = (_event) => {
    videoPlayerPlayheadLoadBarRef.current.style.width = (isNaN(_event.loaded)) ? `0%` :  `${((_event.loaded) * 100).toFixed(2)}%`;
    return;
  };

  const videoPlayerProgress = (_event) => {
    videoPlayerProgressLoad(_event);
    videoPlayerProgressSeek(_event);
    return;
  };

  useEffect(() => {

    // Muted forced Off on mobile (useragent listed tablet/mobile, or non-touch desktop, or super small window size)
    videoPlayerMuted = (videoPlayerAutoplay && (deviceIsMobile || deviceIsTablet || ( 'ontouchstart' in window && window.outerWidth <= 1440) || window.outerWidth <= 767)) ? true : videoPlayerMuted;
    
    // Muted requires volume set so set according to muted
    videoPlayerVolume = (videoPlayerMuted) ? 0 : videoPlayerVolume;

    // Loop forced Off when prefers reduced motion detected
    videoPlayerLoop = ( window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true ) ? false : videoPlayerLoop;

    // Controls
    setVideoPlayerPlaying(videoPlayerAutoplay);

  }, []);

  if ( !videoPlayerHost || videoPlayerHost.toLowerCase() === 'vimeo')
  {
    return(
      <>
        <div ref={videoPlayerWrapperRef} className='content-block content-block-video'>
          <div ref={videoPlayerPlayheadBarRef} className='content-block-video-playhead-bar'>
            <div ref={videoPlayerPlayheadScrubBarRef} className='content-block-video-playhead-scrub-bar'></div>
            <div ref={videoPlayerPlayheadSeekBarRef} className='content-block-video-playhead-seek-bar'></div>
            <div ref={videoPlayerPlayheadLoadBarRef} className='content-block-video-playhead-load-bar'></div>
          </div>
          <div ref={videoPlayerPlaybackSurfaceRef} className='content-block-video-playback-surface content-block-video-playback-surface-none' onClick={() => { setVideoPlayerPlaying(!videoPlayerPlaying); }}></div>
          <ReactPlayerVimeo 
            ref={videoPlayerRef}
            onClickPreview={videoPlayerLightClick}
            onReady={videoPlayerReady}
            onProgress={videoPlayerProgress}
            url={videoPlayerUrl} 
            light={videoPlayerInPreview} // Can also be set to path for replacement cover image/still
            className='content-block-video-player' 
            width={'100%'} 
            height={'auto'} 
            //playsinline={(deviceIsMobile || deviceIsTablet)}
            style={ { 'aspectRatio': `${videoPlayerAspectRatioWidth}/${videoPlayerAspectRatioHeight}`, 'width': '100%', 'height': '100%' } } 
            loop={videoPlayerLoop}
            volume={videoPlayerVolume}
            muted={videoPlayerMuted}
            playing={videoPlayerPlaying}
            config={{ vimeo: { playerOptions: { loop: videoPlayerLoop, muted: videoPlayerMuted, controls: false, title: false } } }}
          />
        </div>
      </>
    );
  }

  if ( videoPlayerHost.toLowerCase() === 'youtube')
  {
    return(<></>
    // NOT COMPLETE - NEEDS DEMO ACCOUNT AND TESTING SET-UP
    // <div className='blog--article-block'>
    //  <ReactPlayerYouTube 
    //    ref={videoPlayer}
    //    onClick={videoPlayerClick}
    //    onReady={videoPlayerReady}
    //    url={videoPlayerUrl} 
    //    light={videoPlayerLight} 
    //    width={'100%'} 
    //    height={'auto'} 
    //    style={ { 'aspectRatio': `${videoPlayerAspectRatioWidth}/${videoPlayerAspectRatioHeight}`, 'width': '100%', 'height': 'auto' } } 
    //    loop={videoPlayerLoop}
    //    muted={videoPlayerMuted}
    //    config={{ youtube: { playerVars: { ... } } }}
    //  />
    // </div>
    );
  }

  return (<></>);
  
}

ContentBlockVideo.propTypes = {
  videoPlayerHost: PropTypes.string,
  videoPlayerUrl: PropTypes.string.isRequired,
  videoPlayerGui: PropTypes.bool.isRequired,
  videoPlayerAspectRatioWidth: PropTypes.number.isRequired,
  videoPlayerAspectRatioHeight: PropTypes.number.isRequired,
  videoPlayerLoop: PropTypes.bool,
  videoPlayerMuted: PropTypes.bool
};

export default ContentBlockVideo;
