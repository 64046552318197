import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import "./content-block-bodyCopy.scss";

function ContentBlockBodyCopy({ bodyCopy }) {
  return (
    <Interweave
      tagName="div"
      className="content-block content-block-bodyCopy"
      content={bodyCopy}
    />
  );
}

ContentBlockBodyCopy.propTypes = {
  bodyCopy: PropTypes.string.isRequired,
};

export default ContentBlockBodyCopy;
