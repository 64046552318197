import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useWindowSize from "hooks/useWindowSize";

const SvgText = (props) => {
  const { text, ...rest } = props;
  const valueSvgRef = useRef();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!valueSvgRef.current) {
      return;
    }

    setTimeout(() => {
      // console.log("REDRAWING, valueSvgRef.current", valueSvgRef.current);
      valueSvgRef.current.forceRedraw();

      const bbox = valueSvgRef.current.childNodes[0].getBBox();
      const heightAdjusted = bbox.height * 0.57;
      const yAdjusted = bbox.y * 0.69;

      valueSvgRef.current.setAttribute(
        "viewBox",
        [bbox.x, yAdjusted, bbox.width, heightAdjusted].join(" ")
      );
    }, 500);
  }, [windowSize]);

  return (
    <svg ref={valueSvgRef} xmlns="http://www.w3.org/2000/svg">
      <g>
        <text x="0" y="0" fontSize="1rem" width="100%" {...rest}>
          {text}
        </text>
      </g>
    </svg>
  );
};

SvgText.displayName = "SvgText";

SvgText.defaultProps = {};

SvgText.propTypes = {
  displayName: PropTypes.string,
  text: PropTypes.string,
};

export default SvgText;
