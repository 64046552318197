import React from "react";
import Hero, { HeroCopy } from "components/common/Hero";
import PageSection from "components/common/PageSection";
import classNames from "utils/classNames";
import "./about.scss";

function AboutPage() {
  return (
    <div className={classNames.page("about")}>
      <Hero
        bgImg={`https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/about-hero.png`}
      >
        <HeroCopy
          title={`<em>LIGHTING TAILORED</em><br /> TO EVERY STAGE.`}
          subtitle={`As a grower, you want as much photosynthetic photon flux density (PPFD) to your plants as possible. The human eye doesn’t see it, but low-frequency lights “blink” many times per second, causing plants to absorb less energy.`}
        />
      </Hero>
      <PageSection modifiers={{ "body-copy": true }}>
        <div className="page-content-grid">
          <div className="grid-item">
            <div className="stats">
              <div className="stat">
                Up to <span className="stat-text">30%</span> increase in PPFD{" "}
                <br />
                over competition
              </div>
              <div className="plus">+</div>
              <div className="stat">
                Greatly improved
                <br />
                <span className="stat-text">UNIFORMITY</span>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <p>
              <em>gGRO™</em> 630W CMH fixtures are reliable, high-frequency grow
              lamps, delivering more energy to plants than low-frequency systems
              with the same input power. The fixture is purpose-built to help
              growers optimize through{" "}
              <em>both the vegetation and flowering stages</em>. That means you
              can guide your plants through their entire growth cycle and
              manipulate their chemical profiles, ensuring they grow up healthy
              and to your high standards.
            </p>
          </div>
        </div>
      </PageSection>
    </div>
  );
}

export default AboutPage;
