import React from 'react';
import PropTypes from "prop-types";

function SocialIconFacebook({color}) {
  return (
    <svg id="social-icon-facebook" className="social-icon" width="72.918" height="72.919" viewBox="0 0 72.918 72.919" aria-hidden="true" focusable="false" role="presentation">
      <title>Facebook</title>
      <path id="social-icon-facebook" d="M62.241,10.678a36.46,36.46,0,1,0,0,51.563,36.461,36.461,0,0,0,0-51.563M48.722,20.683H44.427l-.028,0a4.466,4.466,0,0,0-4.831,4.07v7.006h8.815l-1.468,9.27H39.572V63.972H29.625V41.034H21.261v-9.27h8.35v-6.9c0-6.894,4.079-12.659,11.426-12.206L48.722,13Z" transform="translate(0)" fill="#fff"/>
    </svg>
  );
}

SocialIconFacebook.defaultProps = {
  color: '#FFFFFF'
};

SocialIconFacebook.propTypes = {
  color: PropTypes.string,
};

export default SocialIconFacebook;