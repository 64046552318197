import React from "react";
import PropTypes from "prop-types";
import bem from "easy-bem";
import "./hero.scss";

function Hero({ modifiers, children, bgImg }) {
  const b = bem("hero");
  let heroClassName = b(modifiers);

  const styles = {};

  if (bgImg) {
    styles.backgroundImage = `url('${bgImg}')`;
    heroClassName += " bg-img";
  }

  return (
    <header className={heroClassName} style={styles}>
      <div className="hero__content">{children}</div>
    </header>
  );
}

Hero.propTypes = {
  modifiers: PropTypes.object,
  children: PropTypes.node,
  bgImg: PropTypes.string,
};

export default Hero;
