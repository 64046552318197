import React from "react";
import PropTypes from "prop-types";
import "./content-block-contentImage.scss";

function ContentBlockContentImage({ contentImage, caption, htmlAttrClassName }) {

  //console.log( 'DynamicBlockContentImage', contentImage, caption, htmlAttrClassName);
  const asset = contentImage[0];
  const assetClassName = htmlAttrClassName ?? '';

  if ( caption !== '' )
  {
    return (
      <figure className={ `content-block content-block-contentImage ${ assetClassName }` }>
        <img src={asset.url} alt={asset.title} />
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    );
  }
  else
  {
    return (
      <img src={asset.url} alt={asset.title} />
    );
  }
}

ContentBlockContentImage.propTypes = {
  contentImage: PropTypes.array.isRequired,
  caption: PropTypes.string,
  htmlAttrClassName: PropTypes.string
};

export default ContentBlockContentImage;
