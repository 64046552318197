import React from "react";

function ContactIcon() {
  const strokeWidth = 20;

  return (
    <svg
      className="contact-icon"
      viewBox="0 0 107.59 82.2"
      aria-hidden="false"
      focusable="false"
      role="presentation"
    >
      <g>
        <path
          fill="#4BB749"
          strokeWidth={strokeWidth}
          d="M107.55,7.62c0-1.23-0.26-2.57-0.79-3.68C105.34,0.98,102.75,0,99.57,0c-23.55,0.03-47.1,0.02-70.65,0.02
          c-0.44,0-0.88,0.02-1.31,0.06c-3.95,0.34-6.57,3.12-6.66,7.09c-0.01,0.58,0,1.16,0,2c-1.87-1.09-3.48-1.17-5.13-0.47
          c-3.41,1.46-6.83,2.9-10.21,4.43C4.8,13.49,4,14.07,3.44,14.75c-3.14,3.88-3.9,8.4-3.21,13.2c1.02,7.02,4.13,13.21,8.04,19.01
          c6.76,10.02,15.09,18.55,24.94,25.56c5.92,4.21,12.21,7.71,19.43,9.18c3.69,0.75,7.38,0.77,10.95-0.64
          c2.89-1.14,5.15-2.89,6.22-6.01c0.84-2.44,1.94-4.81,3.04-7.15c1.04-2.21,1.82-4.37-0.01-6.84c0.75,0,1.21,0,1.67,0
          c8.46,0,16.92,0.01,25.39,0c4.74-0.01,7.65-2.85,7.66-7.58C107.6,38.18,107.6,22.9,107.55,7.62z M69.03,65.22
          c-1.14,2.5-2.35,4.99-3.23,7.59c-0.93,2.72-2.99,3.79-5.49,4.38c-1,0.24-2.05,0.28-3.07,0.4c-4.11-0.05-7.84-1.43-11.49-3.1
          c-7-3.21-13.07-7.78-18.72-12.95c-7.88-7.24-14.7-15.3-19.31-25.05c-1.86-3.93-3.26-7.97-3.03-12.42c0.12-2.29,0.72-4.41,2.22-6.18
          c0.34-0.4,0.84-0.73,1.34-0.94c2.93-1.27,5.89-2.46,8.81-3.74c0.81-0.36,1.16-0.19,1.59,0.54c2.52,4.35,5.09,8.67,7.67,12.98
          c0.38,0.63,0.38,1.11,0.03,1.75c-1.57,2.82-3.09,5.67-4.61,8.52c-1.46,2.75-1.18,4.55,1.05,6.77c5.31,5.31,10.61,10.61,15.92,15.91
          c1.82,1.82,3.8,2.16,6.1,0.97c2.97-1.54,5.93-3.13,8.86-4.74c0.7-0.39,1.22-0.44,1.96,0.01c4.27,2.58,8.56,5.12,12.88,7.61
          C69.31,64,69.41,64.4,69.03,65.22z M102.96,53.78c-0.11,1.69-1.06,2.64-2.74,2.66c-2.15,0.03-4.31,0.01-6.47,0.01
          c-9.06,0-18.12,0.01-27.18-0.02c-0.67,0-1.42-0.21-2.01-0.55c-2.36-1.33-4.68-2.74-7-4.15c-1.83-1.11-3.63-1.2-5.54-0.16
          c-2.94,1.61-5.92,3.13-8.86,4.74c-0.68,0.38-1.08,0.3-1.63-0.25c-5.11-5.16-10.23-10.31-15.38-15.43c-0.59-0.58-0.66-1-0.26-1.73
          c1.57-2.82,3.02-5.71,4.61-8.52c1.14-2.02,1.09-3.9-0.11-5.86c-1.5-2.45-2.95-4.92-4.38-7.42c-0.28-0.49-0.45-1.11-0.46-1.68
          c-0.05-2.47-0.02-4.95-0.02-7.42c0-2.55,0.81-3.38,3.36-3.39c11.81-0.01,23.63,0,35.44,0c11.65,0,23.31,0,34.96,0
          c0.52,0,1.04,0.01,1.55,0.08c1.17,0.17,1.83,0.91,2.03,2.04c0.1,0.54,0.08,1.11,0.08,1.67c0,14.72,0,29.44,0,44.16
          C102.97,52.99,102.99,53.39,102.96,53.78z"
        />
        <path
          fill="#4BB749"
          strokeWidth={strokeWidth}
          d="M96.92,12.64c0.49-0.43,0.94-1.03,1.13-1.63c0.32-1.01-0.09-1.9-1.01-2.46c-0.97-0.59-1.92-0.43-2.76,0.29
          c-2.81,2.42-5.62,4.85-8.42,7.28c-6.94,6-13.89,12-20.81,18.03c-0.6,0.52-0.97,0.54-1.59,0c-9.6-8.36-19.23-16.69-28.85-25.02
          c-1.35-1.17-2.75-1.22-3.69-0.11c-0.91,1.08-0.7,2.44,0.6,3.56c5.79,5.01,11.6,10.02,17.39,15.02c1.43,1.24,2.85,2.49,4.38,3.82
          c-2.3,1.84-4.44,3.56-6.58,5.27c-2.68,2.14-5.38,4.27-8.04,6.43c-1.49,1.21-1.59,2.83-0.29,3.86c0.94,0.74,2.08,0.6,3.36-0.42
          c4.77-3.8,9.55-7.6,14.31-11.42c0.56-0.45,0.92-0.52,1.51,0.01c1.59,1.45,3.25,2.83,4.9,4.22c1.29,1.09,2.22,1.1,3.51,0.04
          c0.46-0.38,0.91-0.78,1.36-1.17c1.47-1.27,2.94-2.55,4.43-3.83c0.34,0.23,0.61,0.39,0.86,0.58c5.28,4.06,10.56,8.13,15.84,12.19
          c1.93,1.48,3.83,3,5.8,4.43c1.56,1.13,3.13,0.69,3.71-0.92c0.38-1.07,0-2.01-1.23-2.96c-4.77-3.68-9.55-7.35-14.33-11.02
          c-2.3-1.77-4.6-3.54-7.02-5.4c1.73-1.5,3.34-2.9,4.96-4.3C85.89,22.22,91.42,17.45,96.92,12.64z"
        />
      </g>
    </svg>
  );
}

export default ContactIcon;
