import PropTypes from "prop-types";
import React, {useEffect, useState, useRef, createRef } from "react";
import { NavLink } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import fetchGraphQL from 'gql/utils';
import BlogTagsQuery from 'gql/queries/blogTags.gql';
import './blog-tags.scss';

export default function useBlogTags({uri}) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [tags, setTags] = useState(null);
  
  const tagFilterFormRef = useRef(null);
  const tagFilterArrowRef = useRef(null);
  const tagFilterArrowClassName = 'blog--article-tag-filter-icon';
  const [tagFilterArrowClass, tagFilterArrowClassSet] = useState(tagFilterArrowClassName);
  const tagFilterSpinnerRef = useRef(null);
  const tagFilterSpinnerClassName = 'blog--tag-filter-spinner';
  const [tagFilterSpinnerClass, tagFilterSpinnerClassSet] = useState(tagFilterSpinnerClassName);
  const tagFilterFormIputRefs = useRef([]);
  const tagFilterFormIputPillRefs = useRef([]);

  const activeTagsRef = useRef([]);

  const loadTags = async () => {
    const resp = await fetchGraphQL(BlogTagsQuery, {"uri": uri});
    return resp.tags;
  };

  // Reactive Load tags list from DB
  useEffect(async () => {

    let tagData = tags;

    if(!tagData) {
      
      tagFilterSpinnerShow();

      tagData = await loadTags();
      
      let newTags = tagData.map((tag) => {
        tag.id = parseInt(tag.id);
        return tag;
      });

      setTags(newTags);
    }

    tagFilterSpinnerHide();
    
  }, [searchParams]);
  
  // Initi & Pre-activate & map Tags based on loading query
  useEffect(() => {

    activeTagsRef.current = (searchParams.getAll('tag')
      .filter((tag) => parseInt(tag) === parseInt(tag))
      .map((tag) => tag) ?? []
    );

  }, []);

  useEffect(() => {
    
    TagFilterSelectorListenerAdd();
    
    return () => {
      TagFilterSelectorListenerRemove();
    };

  });

  const tagFilterSpinnerShow = () => { 
    
    tagFilterArrowClassSet(`${tagFilterArrowClassName} hidden`);
    tagFilterSpinnerClassSet(`${tagFilterSpinnerClassName}`);
  };
  
  const tagFilterSpinnerHide = () => { 

    tagFilterArrowClassSet(`${tagFilterArrowClassName}`);
    tagFilterSpinnerClassSet(`${tagFilterSpinnerClassName} hidden`);
  };

  const tagFilterFormQueryClear = () => {
    searchParams.delete('tag');
    activeTagsRef.current = [];
    setSearchParams(searchParams);
  };

  const tagFilterFormQuery = () => {

    // Clear old
    searchParams.delete('tag');

    // Set new
    activeTagsRef.current.forEach((tagId) => {
      
      if(searchParams.has('tag')) {
        // Prevent duplicates
        if (!searchParams.getAll('tag').includes(String(tagId))) {
          searchParams.append('tag', tagId);
        }
      }
      else {
        searchParams.set('tag', tagId);
      }
    });
    
    setSearchParams(searchParams);//, true);

  };

  const tagFilterFormReset = (_resetCallback) => {

    tagFilterSpinnerShow();
    _resetCallback();
    tagFilterFormQueryClear();
  };

  const tagFilterFormSubmit = async (_submitCallback, _args) => {
    
    // Force singular add before submit
    if ( !isNaN(_args?.tagIdAdd) )
    {
      console.log('add tag....');
      tagFilterFormIputRefs.current.forEach((_input) => {
        let tagFilterFormIputRefCurrent = _input.current.querySelector('input');
        if ( parseInt(tagFilterFormIputRefCurrent.value) === parseInt(_args.tagIdAdd) ) tagFilterFormIputRefCurrent.checked = true;
      });
    }

    const tagFilterForm = document.querySelector('.blog--article-tag-form');
    const tagFilterFormData = new FormData(tagFilterForm);
    let tagFilterFormDataValues = Array.from(tagFilterFormData.values());
    
    tagFilterSpinnerShow();
    _submitCallback();

    // Remove omitted items from activeTag & tagFilterFormDataValues list
    if (!isNaN(_args?.tagIdOmmit) && parseInt(_args?.tagIdOmmit))
    {

      activeTagsRef.current = activeTagsRef.current.filter( (_activeTagId) => {
        if (parseInt(_activeTagId) !== parseInt(_args?.tagIdOmmit)) return _activeTagId;
      });

      tagFilterFormDataValues = tagFilterFormDataValues.filter( (_activeValueId) => {
        if (parseInt(_activeValueId) !== parseInt(_args?.tagIdOmmit)) return _activeValueId;
      });

    }
    
    // If not filters selected
    if (tagFilterFormDataValues.length <= 0) 
    {
      tagFilterFormQueryClear();
    }
    else 
    {

      activeTagsRef.current = [];

      for (var entry of tagFilterFormDataValues) {
        
        // Ids only...
        if (!parseInt(entry)) continue;

        activeTagsRef.current = (activeTagsRef.current.length < 1) ? [entry] : [entry, ...activeTagsRef.current];
      }

      if ( activeTagsRef.current.length > 0)
      {
        tagFilterFormQuery();
      }
      else 
      {
        tagFilterFormQueryClear();
      }
      
    }

    return;
  };
    
  function TagFilterForm({type, submitCallback, resetCallback}) {
    
    return (
      <form ref={tagFilterFormRef} className={`blog--article-tag-form blog--article-tag-form--${type}`} tabIndex='0'>
        <header className='blog--article-tag-form-header'>
          <span className='blog--article-tag-form-header-label'>Categories</span>
          <svg className='blog--article-tag-form-close' width="24.259" height="24.259" viewBox="0 0 24.259 24.259" aria-hidden="true" focusable="false"  role="presentation">
            <g id="ui-icon-close-x" transform="translate(-207.793 -349.793)">
              <line id="Line_1" data-name="Line 1" x2="22.844" y2="22.844" transform="translate(208.5 350.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1"/>
              <line id="Line_2" data-name="Line 2" x2="22.844" y2="22.844" transform="translate(231.344 350.5) rotate(90)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1"/>
            </g>
          </svg>
        </header>
        <div className='blog--article-tag-form-main'>
          {tags && tags.map(tag => (
            TagFilterFormInput(tag)
          ))}
        </div>
        <footer className='blog--article-tag-form-footer'>
          {TagFilterFormSubmit(submitCallback)}
          {TagFilterFormReset(resetCallback)}
        </footer>
      </form>
    );

  }

  function TagFilterFormSubmit(_submitCallback) {
    return (
      <div className='blog--article-tag-form-field'>
        <label className='blog--article-tag-form-field-label'>
          <input className='blog--article-tag-form-filter-all-show' type='submit' onClick={(_event) => { _event.preventDefault(); _event.stopPropagation(); tagFilterFormSubmit(_submitCallback); return false; }} value='Show Stories' />
        </label>
      </div>
    );
  }

  function TagFilterFormReset(_resetCallback) {
    return (
      <div className='blog--article-tag-form-field'>
        <label className='blog--article-tag-form-field-label'>
          <input className='blog--article-tag-form-all-clear' type='reset' onClick={(_event) => { _event.preventDefault(); _event.stopPropagation(); tagFilterFormReset(_resetCallback); }} value='Clear Stories' />
        </label>
      </div>
    );
  }

  function TagFilterFormInput(tag) {

    const [precheck, setPrechecked] = useState(false);
    
    const handleChange = () => {
      setPrechecked(!precheck);
    };

    useEffect(() => {
      
      if (!tagFilterFormIputRefs.current[tag.id]) tagFilterFormIputRefs.current[tag.id] = createRef();
      
      setPrechecked(
        searchParams.getAll('tag').filter( (_searchTag) => { 
          return ( parseInt(_searchTag) === parseInt(tag.id)); 
        }).length > 0
      );

    }, [searchParams]);

    return (
      <div key={`${tag.id}`} ref={tagFilterFormIputRefs.current[tag.id]} className='blog--article-tag-form-field'>
        <label className='blog--article-tag-form-field-label'>
          <input className='blog--article-tag-form-field-label-input' type='checkbox' name={`${tag.title.toLowerCase().replace(/\s/g, '-')}`} value={parseInt(tag.id)} checked={precheck} onChange={() => { 
            handleChange(tag); 
          }} />
          <span className='blog--article-tag-form-field-label-text'>{tag.title}</span>
          <svg className='blog--article-tag-form-field-label-input-check' width='19.376' height='15.684' viewBox='0 0 19.376 15.684' focusable='false' aria-hidden='true' role='presentation'>
            <path id='ui-icon-input-check' d='M3.536,12.7l4.4,4.4L21.5,3.535' transform='translate(-2.828 -2.828)' fill='none' stroke='#000' strokeWidth='2'/>
          </svg>
        </label>
      </div>
    );
  }

  function TagFilterSelectorListenerAdd()
  {
    const tagForm = document.querySelector('.blog--article-tag-form');
    const tagFilterPills = document.querySelector('.blog--article-tag-filter-pills');
    const tagFilterSelector = document.querySelector('.blog--article-tag-filter');
    const tagFilterSelectorIcon = document.querySelector('.blog--article-tag-filter-icon');
    const tagFilterSelectorClose = document.querySelector('.blog--article-tag-form-close');

    if ( !tagFilterSelector || !tagFilterSelectorClose ) return;

    tagFilterSelector.addEventListener('pointerdown', () => {

      if ( window.outerWidth >= 768)
      {
        if (tagForm.classList.contains('blog--article-tag-form-in')) 
        {
          tagFilterSelectorIcon.classList.remove('blog--article-tag-filter-icon-turn-up');
          tagFilterSelectorIcon.classList.add('blog--article-tag-filter-icon-turn-down');
          tagForm.setAttribute('style', `height: 0px`);
          tagForm.classList.remove('blog--article-tag-form-in');
          tagForm.classList.add('blog--article-tag-form-out');
          tagFilterPills?.classList.add('blog--article-tag-filter-pills-in');
          tagFilterPills?.classList.remove('blog--article-tag-filter-pills-out');
        }
        else
        {
          tagFilterSelectorIcon.classList.add('blog--article-tag-filter-icon-turn-up');
          tagFilterSelectorIcon.classList.remove('blog--article-tag-filter-icon-turn-down');
          tagForm.setAttribute('style', `height: ${tagForm.scrollHeight}px;`);
          tagForm.classList.add('blog--article-tag-form-in');
          tagForm.classList.remove('blog--article-tag-form-out');
          tagFilterPills?.classList.remove('blog--article-tag-filter-pills-in');
          tagFilterPills?.classList.add('blog--article-tag-filter-pills-out');
        }
      }
      else
      {
        tagForm.removeAttribute('style');
        tagForm.classList.add('blog--article-tag-form-in');
        tagForm.classList.remove('blog--article-tag-form-out');
      }
    });

    tagFilterSelectorClose.addEventListener('pointerdown', () => {
      tagForm.classList.add('blog--article-tag-form-out');
      tagForm.classList.remove('blog--article-tag-form-in');
    });
  }

  function TagFilterSelectorListenerRemove()
  {
    const tagFilterSelector = document.querySelector('.blog--article-tag-filter');
    const tagFilterSelectorClose = document.querySelector('.blog--article-tag-form-close');

    if ( !tagFilterSelector || !tagFilterSelectorClose ) return;
    
    tagFilterSelector.removeEventListener('pointerdown', () => {});
    tagFilterSelectorClose.removeEventListener('pointerdown', () => {});
  }

  function TagFilterSelector()
  {
    return(
      <>
        <div className='blog--article-tag-filter'>
          <span className='blog--article-tag-filter-label'>CATEGORIES</span> 
          <div ref={tagFilterSpinnerRef} className={tagFilterSpinnerClass}>
            <div className='blog--tag-filter-spinner1 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner2 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner3 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner4 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner5 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner6 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner7 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner8 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner9 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner10 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner11 blog--tag-filter-spinner-child'></div>
            <div className='blog--tag-filter-spinner12 blog--tag-filter-spinner-child'></div>
          </div>
          <svg ref={tagFilterArrowRef} className={tagFilterArrowClass} width="13.052" height="11.325" viewBox="0 0 13.052 11.325" aria-hidden="true" focusable="false" role="presentation"><title>CATEGORIES:</title><path id="ui-icon-arrow" d="M153.583,441.058l-6.526-11.325h13.052Z" transform="translate(-147.057 -429.733)" fill="#fefefe"/></svg>
        </div>
      </>
    );
  }

  function TagFilterSelectorPill({submitCallback}) 
  {
    
    if ( !tags || activeTagsRef.current?.length <= 0 ) return(<></>);

    return (
      <div className='blog--article-tag-filter-pills'>
        {
          tags.map((_tag, _tagIndex) => {
            if ( activeTagsRef.current.includes(String(_tag.id)) )
            {
              return (
                <span key={`blog--article-tag-filter-pill-${_tagIndex}`} ref={tagFilterFormIputPillRefs.current[_tag.id]} className='blog--article-tag-filter-pill'>
                  <span className='blog--article-tag-filter-pill-label'>{_tag.title.toUpperCase()}</span>
                  <svg className='blog--article-tag-filter-pill-icon' width="18.381" height="18.381" viewBox="0 0 18.381 18.381" onClick={(_event) => { 
                    _event.stopPropagation();
                    _event.preventDefault();
                    tagFilterFormSubmit(submitCallback, { tagIdOmmit: _tag.id });
                  }}>
                    <g id="icon-x-close-green" transform="translate(-306.826 -424.826)">
                      <line id="x-close-line-two" x2="16.967" y2="16.967" transform="translate(307.533 425.533)" fill="none" stroke="#60cc48" strokeWidth="2"/>
                      <line id="x-close-line-one" x1="16.967" y2="16.967" transform="translate(307.533 425.533)" fill="none" stroke="#60cc48" strokeWidth="2"/>
                    </g>
                  </svg>
                </span>
              );
            }
          })
        }
      </div>
    );
  }
  
  function TagFilterList({tagged, linked = false})
  { 
    if (tagged.length <= 0) return(<></>); 
    
    return tagged.map((_tag, _tagIndex ) => {
      
      let space = ( _tagIndex >= 1 );

      const taggedClick = ( _id ) =>
      {
        tagFilterFormSubmit(() => {}, { tagIdAdd: parseInt(_id) });

        // Scroll grid backinto position
        document.documentElement.scrollTo({ 
          top: document.querySelector('.blog--article-tag-filter').offsetTop - window.outerHeight / 8, 
          left: 0,
          behavior: 'smooth', 
        });
      };
      
      return (linked) 
        ? (window.location.pathname !== '/knowledge-center' && window.location.pathname !== '/knowledge-center/')
          ? (space)
            ? <>,&nbsp; <NavLink to={`/knowledge-center?tag=${_tag.id}`} className={'huh'}>{_tag.title.toUpperCase()}</NavLink></>
            : <NavLink to={`/knowledge-center?tag=${_tag.id}`} className={'huh'}>{_tag.title.toUpperCase()}</NavLink>
          : (space)
            ? <>,&nbsp; <NavLink to={`/knowledge-center?tag=${_tag.id}`} onClick={(_event) => { _event.preventDefault(); _event.stopPropagation(); taggedClick(_tag.id); }}>{_tag.title.toUpperCase()}</NavLink></>
            : <NavLink to={`/knowledge-center?tag=${_tag.id}`} onClick={(_event) => { _event.preventDefault(); _event.stopPropagation(); taggedClick(_tag.id); }}>{_tag.title.toUpperCase()}</NavLink>
        : (space)
          ?  <>,&nbsp; {_tag.title.toUpperCase()}</>
          : _tag.title.toUpperCase();
    });

  }

  function TagFilterAllShowButton() {
    return (
      <a href='/knowledge-center' className='blog--button-green'>All Stories</a>
    );
  }

  TagFilterForm.defaultProps = {
    type: "filter",
    submitCallback: null,
    resetCallback: null,
  };

  TagFilterForm.propTypes = {
    type: PropTypes.string.isRequired,
    submitCallback: PropTypes.func.isRequired,
    resetCallback: PropTypes.func.isRequired
  };

  TagFilterSelectorPill.defaultProps = {
    submitCallback: null,
  };

  TagFilterSelectorPill.propTypes = {
    submitCallback: PropTypes.func.isRequired,
  };

  TagFilterList.defaultProps = {
    tagged: [],
    linked: false
  };

  TagFilterList.propTypes = {
    tagged: PropTypes.array,
    linked: PropTypes.bool,
  };

  return {
    tags, activeTags: activeTagsRef.current, TagFilterForm, TagFilterAllShowButton, TagFilterSelector, TagFilterSelectorPill, TagFilterList
  };
}