import React, {createContext, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';

// load default component config for initialState
const AppContext = createContext();

function getInitialState() {
  const initialState = {
    loading: false,
  };
  return initialState;
}

function reducer(state, action) {
  const { type, payload } = action;
  console.log('APP CONTEXT :: reducer ', state, type, payload);

  switch (type) {
  case "SHOW_LOADER":
    state.loading = false;
    break;
  case "HIDE_LOADER":
    state.loading = true;
    break;
  default:
    throw new Error("unexpected action type");
  }
  return { ...state };
}

const AppContextProvider = (props) => {
    
  const initialState = getInitialState();

  const [state, dispatch] = useReducer(reducer, initialState);

  const showLoader = () => {
    dispatch({type: 'SHOW_LOADER', payload: {}});
  };

  const hideLoader = () => {
    dispatch({type: 'HIDE_LOADER', payload: {}});
  };

  return (
    <AppContext.Provider value={{
      state,
      dispatch,
      showLoader,
      hideLoader,
    }}>
      {props.children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = {
  children: PropTypes.node
};

const useAppContext = () => {
  return useContext(AppContext);
};

export default AppContext;

export {
  AppContextProvider,
  useAppContext
};