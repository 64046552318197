import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "components/global/Router";
//import LoadingScreen from "components/global/LoadingScreen";
import Menu from "components/global/Menu";
import Footer from "components/global/Footer";
import ContactCta from "components/global/ContactCta";
import AnalyticsListener from "./AnalyticsListener";
import { AppContextProvider } from "./context";
import { HelmetProvider } from "react-helmet-async";
import DocumentHead from "./DocumentHead";

import "./app.scss";

function App() {
  
  return (
    <HelmetProvider>
      <AppContextProvider>
        <div className="app">
          <BrowserRouter>
            <AnalyticsListener>
              <DocumentHead />
              <Menu />
              <Router />
              <Footer />
              <ContactCta />
              {/* <LoadingScreen /> */}
            </AnalyticsListener>
            {/* <div className="debug-layout">
              <div></div>
            </div> */}
          </BrowserRouter>
        </div>
      </AppContextProvider>
    </HelmetProvider>
  );
}

export default App;
