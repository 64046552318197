/* eslint-disable no-undef */
import React from "react";
import PageSection from "components/common/PageSection";
import "./spec-chart-callout.scss";
import PropTypes from "prop-types";

import "./spec-chart-callout.scss";

function SpecChartCallout({ src, title }) {
  return (
    <PageSection modifiers={{ callout: "spec-chart" }}>
      <div className="">
        <img className="ggro-spec-chart" src={src} alt={title} />
      </div>
    </PageSection>
  );
}

SpecChartCallout.defaultProps = {
  title: "",
};

SpecChartCallout.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SpecChartCallout;
