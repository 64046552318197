import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import classNames from "utils/classNames";
import PageSection from "components/common/PageSection";
import "./efficiency-callout.scss";

function EfficiencyCallout({ heading, bgImg, bodyCopy }) {
  return (
    <PageSection modifiers={{ callout: "efficiency" }} bgImg={bgImg}>
      <div className="efficiency-callout-container">
        <div className="absolute-image absolute--left">
          <img
            alt=""
            src="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/gGRO-lamp-3.png"
          />
        </div>
        <div className="efficiency-callout-content">
          <div className="grid grid-2 z-2">
            <div className="grid-item grid-item-1">
              <div className={classNames.gridItem("inner")}>
                {heading && <Interweave tagName="h2" content={heading} />}
              </div>
            </div>
            <div className="grid-item grid-item-2">
              <div className={classNames.gridItem("inner")}>
                <Interweave tagName="div" content={bodyCopy} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

EfficiencyCallout.defaultProps = {
  bgImg:
    "https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/greenhouse-background.jpeg",
};

EfficiencyCallout.propTypes = {
  bgImg: PropTypes.string,
  heading: PropTypes.string,
  bodyCopy: PropTypes.string.isRequired,
};

export default EfficiencyCallout;
