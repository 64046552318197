import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import ModifierHelper from 'utils/ModifierHelper';
import bem from 'easy-bem';
import './button.scss';

function Button({label, modifiers, external, onClick, to, ...props}) {

  const b = bem('btn');
  const className = b(modifiers);

  // treat as a link
  if(to) {
    if(external) {
      return (
        <a href={to} className={className} {...props}>
          {label}
          <span></span>
        </a>
      );
    }
    else {
      return (
        <Link to={to} className={className} {...props}>
          {label}
          <span></span>
        </Link>
      );
    }
  }

  // treat as a button
  return (
    <button className={className} onClick={onClick} {...props}>
      {label}
      <span></span>
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  external: false
};

Button.propTypes = {
  label: PropTypes.string,
  modifiers: PropTypes.any,
  onClick: PropTypes.func,
  to: PropTypes.string,
  external: PropTypes.bool,
  props: PropTypes.object,
};

export default Button;