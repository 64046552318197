import React from "react";
import PageSection from "components/common/PageSection";
import classNames from "utils/classNames";
import Button from "components/base/Button";
import "./specifications-callout.scss";

function SpecificationsCallout() {
  return (
    <PageSection modifiers={{ "body-copy": true, callout: "specifications" }}>
      <h2 className="h3">gGRO CMH SPECIFICATIONS</h2>
      <div className="callout_specifications-container">
        <div className="grid-item">
          <div className="grid-item__inner">
            <figure className={classNames.gridItemImage()}>
              <img
                className="spec-image"
                src="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/led/ggro-specs-2.png"
                alt="ggro specs chart"
              />
            </figure>
          </div>
        </div>
        <div className="grid-item">
          <div className="grid-item__inner">
            <figure className={classNames.gridItemImage()}>
              <img
                src="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/ggro-can-run-on-solar.png"
                alt=""
              />
            </figure>
            <figure className={classNames.gridItemImage()}>
              <img
                src="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/ggro-solar-lamp-3.svg"
                alt=""
              />
            </figure>
            <div className="tech-callout__footer">
              {/* <Button
                external={true}
                label="download spec sheet"
                modifiers={{ cta: true, green: true }}
                to="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/gGRO-SpecSheet-3-v1.pdf"
                target="_blank"
                rel="noreferrer"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
}

export default SpecificationsCallout;
