import React from "react";
import PropTypes from "prop-types";
import bem from 'easy-bem';
import './burger.scss';

function Burger ({isOpen, onClick}) {
  
  const b = bem('burger');
  
  return (
    <button
      aria-expanded={isOpen} 
      className={b({expanded: isOpen})}
      onClick={onClick}
    >
      <div className={b('bars')} aria-hidden></div>
    </button>
  );
}

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Burger;