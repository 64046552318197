// /* eslint-disable no-unused-vars */
// import { _ } from "core-js";
import React, {useEffect, useRef} from "react";
import useContactForm from "hooks/useContactForm";
import './contact-form.scss';

function ContactForm() {

  let contactForm = null;
  const contactFormRef = useRef(null);

  const { contactFormOriginalCopy } = useContactForm();

  const contactFormReady = () => {

    contactForm = contactFormOriginalCopy;

    const contactFormSuccess = contactForm.getAttribute('data-success-message');
    const contactFormMessageSeparator = '||||';
    const contactFormClone = contactForm.cloneNode(true);
    const contactFormPath = window.location.pathname;

    // Product QA Fields
    const fieldCanopySize = contactFormClone.querySelector('[name="canopySize"]');
    const fieldLicenseType = contactFormClone.querySelector('[name="licenseType"]');
    const fieldCurrentLighting = contactFormClone.querySelector('[name="currentLighting"]');
    const fieldHowManyLightsDoYouNeed = contactFormClone.querySelector('[name="howManyLightsDoYouNeed"]');

    // Concact Fields;
    const fieldMessage = contactFormClone.querySelector('[name="message"]');

    /*
      CONTACT FORM FUNCTIONS
    */

    const contactFormFieldHide = ( _field ) => {
      _field.classList.add('sr-hide-all');
      _field.setAttribute('hidden', 'true');
    };

    // const contactFormFieldShow = ( _field ) => {
    //   _field.classList.remove('sr-hide-all');
    //   _field.removeAttribute('hidden', 'true');
    // };
    
    /*
      CONTACT FORM CALLBACKS
    */
    
    const contactFormHandleReady = () => {

      // Manage fields in product
      if ( !contactFormPath.includes( '/cmh-vs-led' ) )
      {
        
        [fieldCanopySize, fieldLicenseType, fieldCurrentLighting, fieldHowManyLightsDoYouNeed].forEach( ( _item ) => {

          contactFormFieldHide( _item.parentNode.parentNode );
        
        });
      }

    };
    
    /*
      CONTACT FORM PAGE SET-UP
    */

    // Append the clone in replacement of the placeholder
    contactFormRef.current.querySelector('.contact-form').replaceWith(contactFormClone);

    // Prep the form for display
    contactFormHandleReady();

    /*
        CONTACT FORM LISTENER (FREEFORM TRIGGER ORDER): 
        1. SUBMIT 
        2. AJAX BBEFORE SUBMIT
        3. AJAX AFTER SUBMIT
        4. AJAX ERROR -or- AJAX SUCCESS
    */

    contactForm.addEventListener("submit", function (_event) {
              
      console.log('contactForm default submit in component event....', _event);
    });

    contactForm.addEventListener("freeform-ajax-before-submit", function (_event) {

      // Manage fields in product
      if ( contactFormPath.includes( '/cmh-vs-led' ) )
      {
        
        // Clean-up if prev submit error
        // so we don't duplicte product info...
        if ( !fieldMessage.value.includes(contactFormMessageSeparator) )
        {
          fieldMessage.value += contactFormMessageSeparator;
        }
        else
        {
          fieldMessage.value = fieldMessage.value.split(contactFormMessageSeparator)[0];
        }

        // Add product info to form
        [ fieldCanopySize,
          fieldLicenseType,
          fieldCurrentLighting,
          fieldHowManyLightsDoYouNeed
        ].forEach( ( _item, _itemIndex ) => 
        {
          let  messageHeader = '';
          
          // Nullify un-selected dropdowns.
          if ( _item.value.toLowerCase().includes('select one')) _item.value = '';
          
          // Only append user filled items.
          if (_item.value !== '')
          {

            if ( _itemIndex === 0) messageHeader = `Canopy Size`;
            if ( _itemIndex === 1) messageHeader = `License Type`;
            if ( _itemIndex === 2) messageHeader = `Current Lighting`;
            if ( _itemIndex === 3) messageHeader = `How Many Lights Do You Need?`;

            fieldMessage.value += `\n-----------------------------\n${messageHeader} :: ${_item.value}`;

          }
        });

        // Remove separator
        fieldMessage.value.replace(contactFormMessageSeparator, '');
      
      }

      // Append updated message field value w/ products
      _event.data.set('message', fieldMessage.value);

    });

    contactForm.addEventListener("freeform-ajax-after-submit", function (_event) {

      // console.log('contactForm default ajax-after-submit in component event....');
      _event.preventDefault();

    });

    contactForm.addEventListener("freeform-ajax-error", function (_event) {

      _event.preventDefault();

      return false;

    });

    contactForm.addEventListener("freeform-ajax-success", function (_event) {

      // Remove the form child elements (...to prevent multiple submits) and replace w/ success message.
      contactFormRef.current.innerHTML = `<div class='ff-form-success'><center><p>${contactFormSuccess}</p></center></div>`;
      
      _event.preventDefault();

      return false;

    });

  };

  useEffect(() => {

    if( contactFormOriginalCopy ) contactFormReady();

  }, [contactFormOriginalCopy]);

  return (
    <div ref={contactFormRef} className="contact-form__wrapper" id="contact">
      <div className="contact-form"></div>
    </div>
  );
}

export default ContactForm;