import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  //useParams,
} from "react-router-dom";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// import PageLoader from "./PageLoader";
import Home from "pages/Home";
import About from "pages/About";
import Contact from "pages/Contact";
import Support from "pages/Support";
import Led from "pages/Led";
import {
  KnowledgeCenterLanding,
  KnowledgeCenterArticle,
} from "pages/KnowledgeCenter";
import ErrorPage from "pages/Error";

// function pageClassFromRouteParams(pathname, params) {
//   const segments = pathname.split("/").filter((s) => s.length > 0);
//   console.log(segments, pathname, params);
//   return segments.join("-");
// }

function PageTransition() {

  //const location = useLocation();
  const nodeRef = React.useRef(null);

  return (
    // <TransitionGroup component={null}>
    //   <CSSTransition
    //     nodeRef={nodeRef}
    //     key={location.key}
    //     timeout={750}
    //     classNames="fade"
    //   >
    <main className={`container`} id="page" key="container" ref={nodeRef}>
      <Outlet />
    </main>
    //   </CSSTransition>
    // </TransitionGroup>
  );
}

function Router() {
  
  return (
    <Routes>
      <Route path="/" element={<PageTransition />}>
        <Route classNames="home" index element={<Home />} />
        <Route classNames="led" path="/cmh-vs-led" element={<Led />} />
        <Route classNames="about" path="/about" element={<About />} />
        <Route classNames="knowledge-center knowledge-center-article" path="/knowledge-center/:slug" element={<KnowledgeCenterArticle />} />
        <Route classNames="knowledge-center knowledge-center-home"  path="/knowledge-center" element={<KnowledgeCenterLanding />} />
        <Route classNames="contact" path="/contact" element={<Contact />} />
        <Route classNames="support" path="/support" element={<Navigate to='/' />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

// Router.propTypes = {};

export default Router;
