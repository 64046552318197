import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import classNames from "utils/classNames";

import "./hero-copy.scss";

function HeroCopy({ title, subtitle }) {
  return (
    <div className={classNames.hero("copy")}>
      <Interweave
        tagName="h1"
        content={title}
        className={classNames.hero("title")}
      />
      {subtitle && (
        <Interweave
          tagName="h4"
          content={subtitle}
          className={classNames.hero("subtitle")}
        />
      )}
    </div>
  );
}

HeroCopy.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default HeroCopy;
