import React, { useRef, useEffect, useReducer } from "react";
// import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import fundcanna from './fundcanna.scss';

function Fundcanna() {
  
    useEffect(() => {
      //
    }, []);
  
    return (
      <section className='fundcanna'>
        <article className='fundcanna-article'>
          <header className='fundcanna-header' aria-label="Proud to partner with fundcanna">
              <p className='fundcanna-header-leadin color-black'>Proud to partner with</p>
              <h2 className='fundcanna-header-h2 sr-see'>Fundcanna</h2>
              <a href='https://fundcanna.com/partner/ggro?utm_source=gGro&utm_medium=referral&utm_campaign=gGro&leadsource=referral'><img className='fundcanna-header-logo sr-hide' src='static/home/fundcanna/fundcanna-logo-green-for-green-lock-up-white.png' alt='fundcanna logo type' role='presentation' /></a>
          </header>
          <footer>
            <h3 className='fundcanna-header-h3'><span className='color-black'>Finance Today.</span> <span className='color-white'>Grow Tomorrow.</span></h3>
            <p className='color-white'>Finance your gGRO lights through FundCanna, the cannabis <br className='br-lg' /> industry's top provider for business loans.</p> 
            <p>
              <a className='fundcanna-button-white' href='knowledge-center/does-ggro-provide-financing'>Read More</a>
              <a className='fundcanna-button-black' href='https://fundcanna.com/partner/ggro?utm_source=gGro&utm_medium=referral&utm_campaign=gGro&leadsource=referral' target='_blank'><span className='color-white'>Explore</span> <span className='color-green'>Fundcanna</span></a>
            </p>
          </footer>
        </article>
        <picture className='fundcanna-background picture-object-fit-cover'>
            <source srcSet='static/home/fundcanna/fundcanna-background-green.jpg' />
            <img src='static/home/fundcanna/fundcanna-background-green.jpg' alt='Green colored composite photo of marijuana plant' />
        </picture>
      </section>
    );
  }
  
  fundcanna.propTypes = {};
  
  export default Fundcanna;