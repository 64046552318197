import bem from "easy-bem";

const classNames = {
  page: bem('page'),
  pageSection: bem('page-section'),
  hero: bem('hero'),
  gridItem: bem('grid-item'),
  gridItemImage: bem('grid-item__image')
};

export default classNames;