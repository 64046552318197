import {useEffect, useState } from "react";

export default function useContactForm() {

  let contactFormOriginal = null;
  const [contactFormOriginalCopy, contactFormOriginalCopySet] = useState(null);

  useEffect(() => {

    contactFormOriginal = document.querySelector('[data-handle="contactForm"]');
    
    if( contactFormOriginal )
    {
      if ( !contactFormOriginalCopy ) contactFormOriginalCopySet( contactFormOriginal );
    }

  });

  return { contactFormOriginalCopy };

}