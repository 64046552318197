function shareThis() {

  const env = document.body.dataset.env;
  const shareThisId = ( env === 'local' ) 
    ? '63be0d43457e4b001b975c7e'
    : ( env === 'staging' )
      ? '62ce24edacbf2a0019029d92'
      : '628c01912858d90019b29f57';

  let loadShareThisFollowButtons = `https://platform-api.sharethis.com/js/sharethis.js#property=[propertyId]&product=sop`.replace( '[propertyId]', shareThisId );
  let loadShareThisCustomButtons = `https://platform-api.sharethis.com/js/sharethis.js#property=[propertyId]&product=custom-share-buttons`.replace( '[propertyId]', shareThisId );

  let shareThisScript = document.createElement('script');
  shareThisScript.setAttribute('type', 'text/javascript');
  shareThisScript.setAttribute('async', 'async');
  shareThisScript.setAttribute('src', loadShareThisFollowButtons );
  document.getElementsByTagName('head')[0].append( shareThisScript );

  shareThisScript = document.createElement('script');
  shareThisScript.setAttribute('type', 'text/javascript');
  shareThisScript.setAttribute('async', 'async');
  shareThisScript.setAttribute('src', loadShareThisCustomButtons );
  document.getElementsByTagName('head')[0].append( shareThisScript );

  return;

}

function useShareThis()
{
  return { shareThis };
}

export default useShareThis;
