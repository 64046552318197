import React from "react";
import PropTypes from "prop-types";
import Hero, { HeroCopy } from "components/common/Hero";
import ContactForm from "components/ContactForm";
import PageSection from "components/common/PageSection";
import classNames from "utils/classNames";
import "./contact.scss";
function ContactPage() {
  return (
    <div className={classNames.page("contact")}>
      <Hero bgImg="https://d244yw68jia32n.cloudfront.net/uploads/landing-pages/hps/contact-hero.png">
        <HeroCopy title={`GET IN CONTACT\n WITH OUR TEAM`} />
      </Hero>
      <PageSection modifiers={{ "body-copy": true }}>
        <a name='contact' />
        <h4>Want to know more? Email us and we’ll get right back to you.</h4>
        <ContactForm />
      </PageSection>
    </div>
  );
}

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default ContactPage;
